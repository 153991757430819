import axiosInstance from "../../utils/axiosInstance";

// notification
// import { notify } from "../../components/alerts";

// loading
import {
  setOpportunities,
  setOpportunnity,
} from "../../store/slices/opportunity";
import { setLoading, stopLoading } from "../../store/slices/loading";

export const getOpportunities = async (dispatch, current_page) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(
      `/opportunities?page=${current_page}`
    );

    dispatch(setOpportunities(response.data));
  } catch (err) {
    // logger action
  }
  dispatch(stopLoading());
};

export const getOpportunity = async (dispatch, id) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/show-opportunity/${id}`);

    // console.log(response.data);
    dispatch(setOpportunnity(response.data));
  } catch (err) {
    // logger action
  }
  dispatch(stopLoading());
};
