import React from "react";

const PrivacyPolicy = () => {
  return( 
    <div className='px-14'>
        <h1 className="font-bold text-3xl pb-10">Privacy Policy</h1>
        <p className="text-[14px] md:text-[15px] mb-2">
          This Privacy Policy describes Our policies and procedures on the collection, 
          use and disclosure of Your information when You use the Service and tells You 
          about Your privacyrights and how the law protects You.We use Your Personal data 
          to provide and improve the Service. By using the Service, Youagree to the collection 
          and use of information in accordance with this Privacy Policy.
        </p>
        <h4 className="font-bold mb-4 text-lg">Interpretation</h4>
        <p className="mb-4">
          The words of which the initial letter is capitalized have meanings defined 
          under the following conditions. The following definitions shall have the same 
          meaning regardless of whether they appear in singular or in plural.
        </p>
        <p className="mb-4">Definitions</p>
        <p className="mb-4">For the purposes of this Privacy Policy:</p>
        <p className="mb-4"> <span className="font-bold">● "Account"</span> means a unique account created for You to access our Service or parts of ourService.</p>
        <p className="mb-4">
          <span className="font-bold">● "Application"</span> means the software program provided by the Company downloaded by 
          Youon any electronic device, named [APP_NAME].
        </p>
        <p className="mb-4">
          <span className="font-bold">● "Company"</span> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
          refers to CORPORATE SET-UP.
        </p>
        <p className="mb-4"><span className="font-bold">● "Country"</span> refers to the USER’S country.</p>
        <p className="mb-4">
          <span className="font-bold">● "Cookies"</span> are small files that are placed on Your computer, mobile device 
          or any other device by a website, containing the details of Your browsing history on that 
          website among its many uses.
        </p>
        <p className="mb-4">
          <span className="font-bold">● "Device"</span> means any device that can access the Service such 
          as a computer, a cell phone or a digital tablet.
        </p>
        <p className="mb-4"><span className="font-bold">● "Personal Data"</span> is any information that relates to an identified or identifiable individual.</p>
        <p className="mb-4"><span className="font-bold">● "Service"</span>  refers to the Application.</p>
        <p className="mb-4">
          <span className="font-bold">● "Service Provider"</span>means any natural or legal person who processes the data on 
          behalf ofthe Company. It refers to third-party companies or individuals employed by the Company 
          to facilitate the Service, to provide the Service on behalf of the Company, to perform services related 
          to the Service or to assist the Company in analyzing how the Service is used.
        </p>
        <p className="mb-4">
          <span className="font-bold">● "Usage Data"</span> refers to data collected automatically, either generated by the use of 
          theService or from the Service infrastructure itself (for example, the duration of a page visit).
        </p>
        <p className="mb-4">
          <span className="font-bold">● "You"</span> means the individual accessing or using the Service, or the company, or other 
          legalentity on behalf of which such individual is accessing or using the Service, as applicable.
        </p>
        <h4 className="font-bold mb-4 text-lg">Collecting and Using Your Personal Data</h4>
        <h4 className="font-bold mb-4 text-lg">Types of Data Collected</h4>
        <h4 className="font-bold mb-4 text-lg">Personal Data</h4>
        <p className="mb-4">While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
        <p className="mb-4">● Email address</p>
        <p className="mb-4">● First name and last name</p>
        <p className="mb-4">● Phone number</p>
        <p className="mb-4">● Address, State, Province, ZIP/Postal code, City</p>
        <p className="mb-4">● Usage Data</p>
        <h4 className="font-bold mb-4 text-lg">Usage Data</h4>
        <p className="mb-4">
          Usage Data is collected automatically when using the Service.Usage Data may include information such 
          as Your Device's Internet Protocol address (e.g. IPaddress), browser type, browser version, the pages 
          of our Service that You visit, the time anddate of Your visit, the time spent on those pages, unique 
          device identifiers and otherdiagnostic data.We may also collect information that Your browser sends 
          whenever You visit our Service orwhen You access the Service by or through a mobile device.
        </p>
        <h4 className="font-bold mb-4 text-lg">Information Collected while Using the Application</h4>
        <p className="mb-4">
          While using Our Application, in order to provide features of Our Application, We maycollect, 
          with Your prior permission:
        </p>
        <p className="mb-4">
          ● Information regarding your location we use this information to provide features of our service, 
          to improve and customize our service. The information may be uploaded to the Company's servers 
          and/or a service provider's server or it may be simply stored on your device. You can enable or 
          disable access to this information at any time, through your device settings.
        </p>
        <h4 className="font-bold mb-4 text-lg">Use of Your Personal Data</h4>
        <p className="mb-4">The Company may use Personal Data for the following purposes:</p>
        <p className="mb-4">● To provide and maintain our Service, including to monitor the usage of our Service.</p>
        <p className="mb-4">
          ● To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide 
          can give You access to different functionalities of the Service thatare available to You as a registered user.
        </p>
        <p className="mb-4"> 
          ● For the performance of a contract: the development, compliance and undertaking of the purchase contract 
          for the products, items or services You have purchased or of any other contract with Us through the Service.
        </p>
        <p className="mb-4">
          ● To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic 
          communication, such as a mobile application's push notifications regarding updates or informative 
          communications related to the functionalities, products or contracted services, including the 
          security updates, when necessary or reasonable for their implementation.
        </p>
        <p className="mb-4">
          ● To provide You with news, special offers and general information about other goods,services 
          and events which we offer that are similar to those that you have already purchasedor enquired 
          about unless You have opted not to receive such information.
        </p>
        <p className="mb-4">● To manage Your requests: To attend and manage Your requests to Us.</p>
        <p className="mb-4"> 
          ● For business transfers: We may use Your information to evaluate or conduct a merger, divestiture,
           restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, 
           whether as a going concern or as part of bankruptcy, liquidation, or similarproceeding, in which 
           Personal Data held by Us about our Service users is among the assets transferred.
        </p>
        <p className="mb-4">
          ● For other purposes: We may use Your information for other purposes, such as data analysis, 
          identifying usage trends, determining the effectiveness of our promotional campaigns and to 
          evaluate and improve our Service, products, services, marketing and your experience. We may 
          share Your personal information in the following situations:
        </p>
        <p className="mb-4">
          ● With Service Providers: We may share Your personal information with Service Providers to 
          monitor and analyze the use of our Service, for payment processing, to contact You.
        </p>
        <p className="mb-4">
          ● For business transfers: We may share or transfer Your personal information in connection with, 
          or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all 
          or a portion of Our business to another company.
        </p>
        <p className="mb-4">
          ● With Affiliates: We may share Your information with Our affiliates, in which case we will 
          require those affiliates to honor this Privacy Policy.
        </p>
        <p className="mb-4">
          ● With other users: when You share personal information or otherwise interact in the public 
          areas with other users, such information may be viewed by all users and may be publicly 
          distributed outside.
        </p>
        <p className="mb-4">● With Your consent: We may disclose Your personal information for any other purpose with Your consent.</p>
        <h4 className="font-bold mb-4 text-lg">Retention of Your Personal Data</h4>
        <p className="mb-4">
          The Company will retain Your Personal Data only for as long as is necessary for the 
          purposesset out in this Privacy Policy. We will retain and use Your Personal Data to 
          the extent necessary to comply with our legal obligations (for example, if we are required 
          to retain yourdata to comply with applicable laws), resolve disputes, and enforce our legal 
          agreements andpolicies.The Company will also retain Usage Data for internal analysis purposes. 
          Usage Data isgenerally retained for a shorter period of time, except when this data is used to 
          strengthen thesecurity or to improve the functionality of Our Service, or We are legally obligated 
          to retainthis data for longer time periods.
        </p>
        <h4 className="font-bold mb-4 text-lg">Transfer of Your Personal Data</h4>
        <p className="mb-4">
          Your information, including Personal Data, is processed at the Company's operating offices 
          and in any other places where the parties involved in the processing are located. It means 
          that this information may be transferred to — and maintained on — computers located outside of
          Your state, province, country or other governmental jurisdiction where the data protection
          laws may differ than those from Your jurisdiction.Your consent to this Privacy Policy followed 
          by Your submission of such information represents Your agreement to that transfer.The Company 
          will take all steps reasonably necessary to ensure that Your data is treated securely and in 
          accordance with this Privacy Policy and no transfer of Your Personal Data will take place to 
          an organization or a country unless there are adequate controls in place including the 
          security of Your data and other personal information.
        </p>
        <h4 className="font-bold mb-4 text-lg">Law enforcement</h4>
        <p className="mb-4">
          Under certain circumstances, the Company may be required to disclose Your Personal Data if 
          required to do so by law or in response to valid requests by public authorities (e.g. a 
          court ora government agency).
        </p>
        <h4 className="font-bold mb-4 text-lg">Other legal requirements</h4>
        <p className="mb-4">The Company may disclose Your Personal Data in the good faith belief that such action isnecessary to:</p>
        <p className="mb-4">● Comply with a legal obligation</p>
        <p className="mb-4">● Protect and defend the rights or property of the Company</p>
        <p className="mb-4">● Prevent or investigate possible wrongdoing in connection with the Service</p>
        <p className="mb-4">● Protect the personal safety of Users of the Service or the public</p>
        <p className="mb-4">● Protect against legal liability</p>
        <h4 className="font-bold mb-4 text-lg">Security of Your Personal Data</h4>
        <p className="mb-4">
          The security of Your Personal Data is important to Us, but remember that no method of transmission 
          over the Internet, or method of electronic storage is 100% secure. While We strive to use 
          commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
        </p>
        <h4 className="font-bold mb-4 text-lg">Detailed Information on the Processing of Your Personal Data</h4>
        <p className="mb-4">
          The Service Providers We use may have access to Your Personal Data. These third-party 
          vendors collect, store, use, process and transfer information about Your activity on 
          Our Service in accordance with their Privacy Policies. Analytics We may use third-party 
          Service providers to monitor and analyze the use of our Service.
        </p>
        <h4 className="font-bold mb-4 text-lg">Email Marketing</h4>
        <p className="mb-4">
          We may use Your Personal Data to contact You with newsletters, marketing or promotional
          materials and other information that may be of interest to You. You may opt-out of receiving
          any, or all, of these communications from Us by following the unsubscribe link orinstructions 
          provided in any email We send or by contacting Us.
        </p>
        <h4 className="font-bold mb-4 text-lg">Children's Privacy</h4>
        <p className="mb-4">
          Our Service does not address anyone under the age of 18. We do not knowingly collect personally 
          identifiable information from anyone under the age of 18. If You are a parent orguardian and 
          You are aware that Your child has provided Us with Personal Data, please contact Us. If We 
          become aware that We have collected Personal Data from anyone under theage of 18 without 
          verification of parental consent, We take steps to remove that information from Our servers.
          If We need to rely on consent as a legal basis for processing Your information and Your country 
          requires consent from a parent, We may require Your parent's consent before We collect and use that information.
        </p>
        <h4 className="font-bold mb-4 text-lg">Links to Other Websites</h4>
        <p className="mb-4">
          Our Service may contain links to other websites that are not operated by Us. If You click on a 
          third party link, You will be directed to that third party's site. We strongly advise You to
          review the Privacy Policy of every site You visit. We have no control over and assume no responsibility 
          for the content, privacy policies or practices of any third party sites or services.
        </p>
        <h4 className="font-bold mb-4 text-lg">Changes to this Privacy Policy</h4>
        <p className="mb-4">
          We may update Our Privacy Policy from time to time. We will notify You of any changes by posting 
          the new Privacy Policy on this page. We will let You know via email and/or a prominent notice on 
          Our Service, prior to the change becoming effective and update the "Last updated" date at the top 
          of this Privacy Policy.You are advised to review this Privacy Policy periodically for any changes. 
          Changes to this Privacy Policy are effective when they are posted on this page.
        </p>
        <h4 className="font-bold mb-4 text-lg">Contact Us</h4>
        <p className="mb-4">If you have any questions about this Privacy Policy, You can contact us:</p>
        <p className="mb-4">● By visiting this page on our website: https://www.corporate-setup.com</p>
        <p className="mb-4">● By sending us an email: setup@corporate-setup.com</p>
    </div>
    )
  ;
};

export default PrivacyPolicy;
