import React from "react";
import ServiceLargeCard from "../../../components/ui/Cards/ServiceLargeCard";

const UnregisteredCompanyUser = () => {
  return (
    <div className="flex justify-between items-center">
      <ServiceLargeCard
        title="Already have a company?"
        sub_title="Enter company details"
        button_title="Enter"
        link="/dashboard/company-update"
      />
      <ServiceLargeCard
        title="Start a company?"
        sub_title="Incorporate now"
        button_title="Continue"
        link="/dashboard/services"
      />
    </div>
  );
};

export default UnregisteredCompanyUser;
