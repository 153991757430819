import React from "react";

// components
import Paginate from "../../../components/ui/paginate";
import HeaderTitle from "../../../components/ui/title";
import TrainingCard from "../misc/TrainingCard";

// state and service
import { get_blogs } from "../../../service/api/blog";
import { onNext, onBack } from "../../../store/slices/blog";
import { useDispatch, useSelector } from "react-redux";

const BlogDashboard = () => {
  const dispatch = useDispatch();
  const current_page = useSelector(({ blog }) => blog.current_page);
  const last_page = useSelector(({ blog }) => blog.last_page);
  const blogs = useSelector(({ blog }) => blog.blogs);

  const onLoad = React.useCallback(() => {
    get_blogs(dispatch, current_page);
  }, [current_page, dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div>
      <HeaderTitle
        title="Corporate Setup Blog"
        sub_title="All you need know about our training programs."
      />
      <div className="flex flex-wrap justify-between h-full bg-[white] px-4 py-5">
        {blogs.map((item) => (
          <TrainingCard key={item.id} item={item} />
        ))}
      </div>
      <Paginate
        current_page={current_page}
        last_page={last_page}
        onNext={dispatch(onNext)}
        onBack={dispatch(onBack)}
      />
    </div>
  );
};

export default BlogDashboard;
