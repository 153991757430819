import React from "react";

// navigation
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { registerUser } from "../../../service/api/auth";

const useRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, dataSet] = React.useState({
    name: "",
    phone: "",
    address: "",
    accept_policy: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const [error, errorSet] = React.useState({
    name: "",
    phone: "",
    address: "",
    accept_policy: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  const validate = () => {
    let error = false;
    if (!data.name) {
      error = true;
      errorSet((prev) => ({ ...prev, name: "warning" }));
    }
    if (!data.phone) {
      error = true;
      errorSet((prev) => ({ ...prev, phone: "warning" }));
    }
    if (!data.email) {
      error = true;
      errorSet((prev) => ({ ...prev, email: "warning" }));
    }
    if (!data.password) {
      error = true;
      errorSet((prev) => ({ ...prev, password: "warning" }));
    }
    if (data.password !== data.password_confirmation) {
      error = true;
      errorSet((prev) => ({ ...prev, password_confirmation: "warning" }));
    }
    if (!data.accept_policy) {
      error = true;
      errorSet((prev) => ({ ...prev, accept_policy: "warning" }));
    }
    return error ? false : true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const response = await registerUser(data, dispatch);
      response && navigate("/login");
    }
    return;
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    errorSet((prev) => ({ ...prev, [name]: "" }));
    dataSet((prev) => ({ ...prev, [name]: value }));
  };

  return { data, dataSet, error, errorSet, onSubmit, onChange };
};

export default useRegister;
