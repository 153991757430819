import React from "react";

// components
import { Layout, Menu } from "antd";
import sidebar from "../../../constants/sidebar";

// router dom
import { useNavigate } from "react-router-dom";

// constants
import crest from "../../../assets/images/icons/logo.png";

const { Sider } = Layout;

const SideBar = () => {
  const navigate = useNavigate();
  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      style={{ backgroundColor: "white" }}
      onBreakpoint={(broken) => {
        // logger for web mobile
      }}
      onCollapse={(collapsed, type) => {
        // onCollapse for web logger
      }}
    >
      <div className="bg-[#67C748] h-[60%] overflow-hidden rounded-tr-[50px]">
        <div className="logo md:py-5 md:px-3 md:my-5">
          <img src={crest} alt="Corporate Setup Crest" className="h-[50px] " />
        </div>

        <Menu theme="transparent" mode="inline" defaultSelectedKeys={["1"]}>
          {sidebar.nav_links.map((item) => (
            <Menu.Item
              onClick={() => navigate(item.link)}
              key={item.key.toString()}
              className="text-[white] hover:text-[#000] hover:bg-[#297110] bg-opacity-25"
            >
              <div className="text-[white] flex items-center">
                <div className="flex items-center mx-2">
                  <img
                    src={item.icon}
                    className="h-[25px] w-[25px]"
                    alt={item.label}
                  />
                </div>
                <div className="flex hover:text-[#000] items-center text-[18px]">
                  {item.label}
                </div>
              </div>
            </Menu.Item>
          ))}
        </Menu>
      </div>
      <div className="bg-[#67C748] h-[40%] overflow-hidden flex items-end border-t border-[white]">
        <Menu theme="transparent" mode="inline" defaultSelectedKeys={["1"]}>
          {sidebar.settings.map((item) => (
            <Menu.Item
              onClick={() => navigate(item.link)}
              key={item.key.toString()}
              className="text-[white] hover:bg-[#297110] bg-opacity-25"
            >
              <div className="text-[white] flex items-center">
                <div className="flex items-center mx-2">
                  <img
                    src={item.icon}
                    className="h-[25px] w-[25px]"
                    alt={item.label}
                  />
                </div>
                <div className="flex hover:text-[#000] items-center text-[15px]">
                  {item.label}
                </div>
              </div>
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </Sider>
  );
};

export default SideBar;
