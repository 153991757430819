import React from "react";
import { useDispatch, useSelector } from "react-redux";
import UnregisteredCompanyUser from "../misc/UnregisteredCompanyUser";
import RegisteredUser from "../misc/RegisteredUser";

import { setTitle } from "../../../store/slices/navbar";

const Dashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const company_status = useSelector(({ auth }) => auth.company_status);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle("Dashboard"));
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full px-10 pt-3 h-full">
      <h4 className="text-[20px]">Welcome {user?.profile?.name}</h4>
      <div
        className={`py-10 px-10 ${
          company_status ? "bg-[white] h-full mb-5" : ""
        }`}
      >
        {company_status ? <RegisteredUser /> : <UnregisteredCompanyUser />}
      </div>
    </div>
  );
};

export default Dashboard;
