import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  services: [],
  current_page: 1,
  last_page: 1,
  service: null,
};

export const service = createSlice({
  name: "service",
  initialState,
  reducers: {
    setServices: (state, action) => {
      state.services = action.payload.data;
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
    },
    setService: (state, action) => {
      state.service = action.payload;
    },
    onNext: (state) => {
      state.current_page = state.current_page + 1;
    },
    onBack: (state) => {
      state.current_page = state.current_page - 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setServices, setService, onNext, onBack } = service.actions;

export default service.reducer;
