import React from "react";

// react router
import { getOpportunity } from "../../../service/api/opportunity";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../../store/slices/navbar";
import { AiOutlineDownload } from "react-icons/ai";

const OpportunityDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const opportunity = useSelector(({ opportunity }) => opportunity.opportunity);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle(opportunity?.opportunity_name));
    getOpportunity(dispatch, id);
  }, [dispatch, id, opportunity?.opportunity_name]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full px-3 py-5 h-full">
      <div className="flex flex-col h-full bg-[white] px-4 py-5 overflow-y-auto overflow-x-hidden">
        <div className="w-full h-[40vh]">
          <img
            src={opportunity?.opportunity_image}
            alt={opportunity?.opportunity_name}
            className="object-contain w-full h-full"
          />
        </div>
        <div className="w-full py-5">
          <div className="w-full flex justify-end">
            <Link to={opportunity?.opportunity_file} target="_blank" download>
              <AiOutlineDownload size={40} className="cursor-pointer" />
            </Link>
          </div>
          <div className="text-[18px] py-4">
            {opportunity?.opportunity_description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpportunityDetails;
