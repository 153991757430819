import React from "react";

const Tabs = ({ items, active, onChange }) => {
  const description = items.find((item) => item.key === active);

  return (
    <div className="flex md:flex-row md:justify-between flex-col">
      <div
        className={`w-full flex flex-row text-center md:flex-col py-4 md:w-[25%] md:text-left md:px-3 md:py-5`}
      >
        {items.map((item) => (
          <div
            className={`w-[50%] text-[15px] md:text-[18px] py-3 md:w-full md:px-4 md:py-5 ${
              active === item.key
                ? "border-b-2 md:border-b-0 md:border-l-4 border-bggreen"
                : ""
            }`}
            key={item.key}
          >
            <p className="cursor-pointer" onClick={() => onChange?.(item.key)}>
              {item.name}
            </p>
          </div>
        ))}
      </div>
      <div className="md:w-[75%] px-4 py-7">{description?.component}</div>
    </div>
  );
};

export default Tabs;
