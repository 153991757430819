import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  blogs: [],
  current_page: 1,
  last_page: 1,
  blog: null,
};

export const blog = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setBlogs: (state, action) => {
      state.blogs = action.payload.data;
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
    },
    setBlog: (state, action) => {
      state.blog = action.payload?.[0];
    },
    onNext: (state) => {
      state.current_page = state.current_page + 1;
    },
    onBack: (state) => {
      state.current_page = state.current_page - 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBlogs, setBlog, onNext, onBack } = blog.actions;

export default blog.reducer;
