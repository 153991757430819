import React from "react";

// component
import { Layout } from "antd";

// constants
import c_avatar from "../../../assets/images/statics/user.png";

// state
import { getProfile } from "../../../service/api/auth";
import { useDispatch, useSelector } from "react-redux";

const { Header } = Layout;

const NavigationBar = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const navbar = useSelector(({ navbar }) => navbar);

  const onLoad = React.useCallback(() => {
    getProfile(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const avatar = user?.profile?.avatar || c_avatar;

  return (
    <Header
      className="items-center flex justify-between px-3"
      style={{ background: "white" }}
    >
      <div className="text-[20px] font-bold mx-3 flex">{navbar?.title}</div>
      <div className="flex overflow-hidden items-center">
        <img
          src={avatar}
          alt="Profile"
          className="h-[35px] w-[35px] rounded-full border mr-2"
        />
        <p className="text-[#000] text-[15px]">{user?.profile?.name}</p>
      </div>
    </Header>
  );
};

export default NavigationBar;
