import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  opportunities: [],
  current_page: 1,
  last_page: 1,
  opportunity: null,
};

export const opportunity = createSlice({
  name: "opportunity",
  initialState,
  reducers: {
    setOpportunities: (state, action) => {
      state.opportunities = action.payload.data;
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
    },
    setOpportunnity: (state, action) => {
      state.opportunity = action.payload;
    },
    onNext: (state) => {
      state.current_page = state.current_page + 1;
    },
    onBack: (state) => {
      state.current_page = state.current_page - 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOpportunities, setOpportunnity, onNext, onBack } =
  opportunity.actions;

export default opportunity.reducer;
