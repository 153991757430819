import React from "react";

// components
import Button from "../../../components/elements/form/Button.jsx";
import MakeRequest from "../misc/MakeRequest";
import SweetAlert from "../../../components/ui/popup";

// router
import { useParams } from "react-router-dom";

// state
import { useDispatch, useSelector } from "react-redux";
import { get_service } from "../../../service/api/service";
import { setTitle } from "../../../store/slices/navbar.js";

const ViewService = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const service = useSelector(({ service }) => service.service);
  const loading = useSelector(({ loading }) => loading.loading);
  const [request, requestSet] = React.useState(false);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle(service?.name));
    get_service(dispatch, id);
  }, [dispatch, id, service?.name]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full px-3 py-5 h-full overflow-hidden">
      <div className="w-full h-full bg-[white] px-4 py-5 overflow-hidden">
        <h1 className="text-[20px] font-[600] text-center capitalize my-3">
          {service?.name}
        </h1>

        <div className="flex h-full items-center justify-center">
          <div className="w-[45%] mr-3">
            <img
              src={service?.description}
              className="w-full h-[40vh]"
              alt={service?.name}
            />
          </div>
          <div className="w-[50%]">
            <div className="border rounded-lg text-center">
              {service?.packages.map((item) => (
                <div
                  className="text-[15px] font-[400] capitalize text-center border-b py-2"
                  key={item?.id}
                >
                  {item.name}
                </div>
              ))}
              <div className="w-[50%] relative">
                <Button
                  type="button"
                  title="Continue"
                  loading={loading}
                  onHandler={() => requestSet(true)}
                  className="w-[50%] bg-bggreen text-[white] absolute"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <MakeRequest
        id={service?.id}
        visible={request}
        onFinish={() => requestSet(false)}
      />
      <SweetAlert />
    </div>
  );
};

export default ViewService;
