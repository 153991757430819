import React from "react";

const NewLetterInput = ({ placeholder, onChange, name }) => {
  return (
    <input
      name={name}
      className="w-60 py-3 text-bggray text-center rounded-md outline-none"
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default NewLetterInput;
