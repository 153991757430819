import React from "react";

// components
import Content from "./content";
import SideBar from "./sidebar";
import { Layout } from "antd";

const AppTheme = ({ children }) => {
  return (
    <Layout className="h-screen">
      <SideBar />
      <Content children={children} />
    </Layout>
  );
};

export default AppTheme;
