import React from "react";

// components
import SelectInput from "../../../components/elements/form/SelectInput";
import TextBox from "../../../components/elements/form/TextBox";
import Button from "../../../components/elements/form/Button";
import { Modal } from "antd";

// state
import { getCountries, getStates } from "../../../service/api/misc";
import { request_service } from "../../../service/api/service";
import { useDispatch, useSelector } from "react-redux";

const MakeRequest = ({ id, visible, onFinish }) => {
  const dispatch = useDispatch();
  const loading = useSelector(({ loading }) => loading.loading);
  const countries = useSelector(({ misc }) => misc.countries);
  const states = useSelector(({ misc }) => misc.states);
  const [data, dataSet] = React.useState({
    service_id: "",
    state_id: "",
    country_id: "",
    others: "",
  });

  const [error, errorSet] = React.useState({
    state_id: "",
  });

  const setId = React.useCallback(() => {
    if (id) {
      dataSet((prev) => ({ ...prev, service_id: id }));
    }
  }, [id]);

  const onLoad = React.useCallback(() => {
    getCountries(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
    setId();
  }, [onLoad, setId]);

  const onSubmit = () => {
    if (!data.state_id) {
      errorSet((prev) => ({
        ...prev,
        state_id: "warning",
        country_id: "warning",
      }));
      return;
    }
    request_service(dispatch, data);
    onFinish?.();
  };

  return (
    <Modal open={visible} footer={null} closable={false} centered>
      <div>
        <div className="my-3">
          <TextBox
            label="Others"
            name="others"
            value={data.others}
            onChange={(e) =>
              dataSet((prev) => ({ ...prev, others: e.target.value }))
            }
          />
        </div>
        <div className="flex justify-between">
          <div className="w-[49%]">
            <SelectInput
              label="Country"
              items={countries}
              name="country"
              status={error.country_id}
              onChange={(e) => {
                getStates(e.target.value, dispatch);
                dataSet((prev) => ({ ...prev, country_id: e.target.value }));
              }}
            />
          </div>

          <div className="w-[49%]">
            <SelectInput
              label="State"
              items={states}
              name="state_id"
              status={error.state_id}
              onChange={(e) => {
                dataSet((prev) => ({ ...prev, state_id: e.target.value }));
                errorSet((prev) => ({ ...prev, country_id: "", state_id: "" }));
              }}
            />
          </div>
        </div>

        <div className="my-4">
          <Button
            title="Make Request"
            onHandler={onSubmit}
            loading={loading}
            className="bg-bggreen text-[white]"
          />
        </div>
      </div>
    </Modal>
  );
};

export default MakeRequest;
