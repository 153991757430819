import React from "react";

const useCompany = () => {
  const [data, dataSet] = React.useState({
    rc_number: "",
    company_name: "",
    company_phone: "",
    company_email: "",
    company_website: "",
    company_address: "",
    company_country: "",
    company_state: "",
  });
  const [error, errorSet] = React.useState({
    rc_number: "",
    company_name: "",
    company_phone: "",
    company_email: "",
    company_website: "",
    company_address: "",
    company_country: "",
    company_state: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errorSet((prev) => ({ ...prev, [name]: "" }));
  };

  const onValidate = () => {};

  return { data, onChange, error, onValidate };
};

export default useCompany;
