import document from "../assets/images/icons/document.png";
import opportunity from "../assets/images/icons/opportunity.png";

// components

const dashboard = {
  dasboard: [
    {
      label: "Document",
      key: "1",
      icon: document,
      link: "/dashboard/documents",
    },
    {
      label: "Opportunity",
      key: "2",
      icon: opportunity,
      link: "/dashboard/opportunity",
    },
  ],
  links: [
    { name: "Document File", link: "/dashboard/documents", key: 1 },
    { name: "Services", link: "/dashboard/services", key: 2 },
    { name: "Opportunities", link: "/dashboard/opportunity", key: 3 },
  ],
};
export default dashboard;
