import React from "react";
import {Spin} from 'antd';

const TextInput = ({ label, name, value, type, placeholder, required, status, onChange, icon, loading, ...rest }) => {
  const borderColor = status ? 'border-[red]' : 'border-bggreen'
  return (
    <div className='w-full'>
      <label className='block font-medium'>{label}</label>
      <div className={`flex w-full border items-center outline-none mt-3 text-gray-900 px-3 py-2 focus:shadow-sm bg-[white] ${borderColor}`}>
        {icon && <div className='mr-1 border-r pr-2'>{icon}</div>}
        <input
          type={type}
          value={value}
          name={name}
          onChange={onChange}
          placeholder={placeholder} 
          required={required}
          className='w-full !outline-none px-0 py-0 placeholder:text-gray-400 border-none focus:border-transparent bg-[white] focus:ring-0'
          {...rest}
        />
        {loading && <Spin size='small' />}
      </div>
    </div>
  )
}

export default TextInput

