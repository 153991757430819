import React from "react";
import axiosUpload from "../../utils/axiosUpload";

// upload

const useUploadDocument = () => {
  const [progress, progresSet] = React.useState(false);

  const uploadImage = async (file) => {
    progresSet(true);
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axiosUpload.post("/upload", formData, {
        transformRequest: (formData) => formData,
      });

      console.log(response.data);
    } catch (error) {}
    progresSet(false);
  };

  const uploadDocument = async (file) => {
    progresSet(true);
    let result = false;
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axiosUpload.post("/upload", formData, {
        transformRequest: (formData) => formData,
      });

      result = response.data;
    } catch (error) {
      result = error?.response.data;
    }
    progresSet(false);
    return result;
  };
  return { progress, uploadDocument, uploadImage };
};

export default useUploadDocument;
