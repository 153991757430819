import React from "react";

// import logo from "../../../assets/images/icons/logo.png";
import TextInput from "../../../components/elements/form/TextInput";
// component
import { Divider } from "antd";
import Button from "../../../components/elements/form/Button";

// navigator
import { Link } from "react-router-dom";

// constants
import { useSelector } from "react-redux";
import useRegister from "../hooks/useRegister";
import CheckBox from "../../../components/elements/form/CheckBox";

const Register = () => {
  const loading = useSelector(({ loading }) => loading.loading);
  const { data, error, errorSet, dataSet, onSubmit, onChange } = useRegister();

  return (
    <div className="flex items-center justify-center">
      <div className="flex md:py-10 sm:px-6 lg:px-8 md:w-[65%]">
        <div className="w-full px-5 py-8">
          <div>
            {/* <img className="h-24" src={logo} alt="Your Company" /> */}
            <h6 className="text-xl font-bold tracking-tight text-gray-900">
              Register with Us!
            </h6>
            <p className="mt-2 text-sm text-gray-600">
              Already Have an account?
              <Link
                to="/login"
                className="font-medium text-bggreen hover:text-midnight-200"
              >
                {" "}
                Sign in
              </Link>
            </p>
          </div>

          <form onSubmit={onSubmit}>
            <div className="my-5 flex md:items-center md:justify-between">
              <div className="w-full md:w-[49%]">
                <TextInput
                  type="text"
                  label="Full Name"
                  name="name"
                  value={data.name}
                  placeholder="Full Name"
                  required={true}
                  onChange={onChange}
                  status={error.name}
                />
              </div>
              <div className="w-full md:w-[49%]">
                <TextInput
                  type="text"
                  label="Phone Number"
                  name="phone"
                  value={data.phone}
                  placeholder="Phone Number"
                  required={true}
                  onChange={onChange}
                  status={error.phone}
                />
              </div>
            </div>

            <div className="my-5 flex md:items-center md:justify-between">
              <div className="w-full md:w-[49%]">
                <TextInput
                  type="text"
                  label="Email"
                  name="email"
                  value={data.email}
                  placeholder="Email"
                  required={true}
                  onChange={onChange}
                  status={error.email}
                />
              </div>
              <div className="w-full md:w-[49%]">
                <TextInput
                  type="text"
                  label="Address"
                  name="address"
                  value={data.address}
                  placeholder="Address"
                  required={true}
                  onChange={onChange}
                  status={error.address}
                />
              </div>
            </div>

            <div className="my-5 flex md:items-center md:justify-between">
              <div className="w-full md:w-[49%]">
                <TextInput
                  type="password"
                  label="Password"
                  name="password"
                  value={data.password}
                  placeholder="Password"
                  required={true}
                  onChange={onChange}
                  status={error.password}
                />
              </div>
              <div className="w-full md:w-[49%]">
                <TextInput
                  type="password"
                  label="Confirm Password"
                  name="password_confirmation"
                  value={data.password_confirmation}
                  placeholder="Comfirm Password"
                  required={true}
                  onChange={onChange}
                  status={error.password_confirmation}
                />
              </div>
            </div>

            <div className="flex justify-between">
              <div className="">
                <CheckBox
                  name="accept_policy"
                  value={data.accept_policy ? true : false}
                  status={error.accept_policy}
                  onChange={() => {
                    dataSet((prev) => ({
                      ...prev,
                      accept_policy: data.accept_policy ? "0" : "1",
                    }));
                    errorSet((prev) => ({ ...prev, accept_policy: "" }));
                  }}
                  label="Accept our terms and condition"
                />
              </div>
              <div className="text-[14.5px]"> View our <Link to='/terms-and-condition' className="text-bggreen ">terms and condition</Link></div>
            </div>

            <div className="flex justify-center items-center flex-col py-10">
              <div className="w-[70%]">
                <Button
                  title="Sign In"
                  type="submit"
                  loading={loading}
                  onHandler={() => null}
                  className="bg-bggreen text-white"
                />
                <Divider>Or</Divider>
                <Button
                  type="button"
                  title="Sign Up with Google"
                  onHandler={() => null}
                  className="bg-[white] border text-[black]"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
