import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  trainings: [],
  current_page: 1,
  last_page: 1,
  training: null,
};

export const training = createSlice({
  name: "training",
  initialState,
  reducers: {
    setTrainings: (state, action) => {
      state.trainings = action.payload.data;
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
    },
    setTraining: (state, action) => {
      state.training = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTrainings, setTraining } = training.actions;

export default training.reducer;
