import React from "react";

// components
import Tabs from "../../components/ui/tabs";
import TermsAndCondition from "./terms_and_condition";
import PrivacyPolicy from "./privacy_policy";

const items = [
  { key: 1, name: "Privacy Policy", component: <PrivacyPolicy /> },
  { key: 2, name: "Terms and Conditions", component: <TermsAndCondition /> },
];

const Policy = () => {
  const [active, activeSet] = React.useState(items?.[0].key);
  return (
    <Tabs items={items} active={active} onChange={(res) => activeSet(res)} />
  );
};

export default Policy;
