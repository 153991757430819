import React from "react";

// react router
import { useParams } from "react-router-dom";

// constants
import { FaGlobe } from "react-icons/fa";

// state
import { getTraining } from "../../../service/api/training";
import { useDispatch, useSelector } from "react-redux";

const TrainingDetail = () => {
  const dispatch = useDispatch();
  const training = useSelector(({ training }) => training.training);
  const { id } = useParams();

  const onLoad = React.useCallback(() => {
    getTraining(dispatch, id);
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full px-3 py-5 h-full">
      <div className="flex h-full bg-[white] px-4 py-5">
        <div className="w-[45%] mr-3">
          <img
            src={training?.image}
            className="w-full h-[50vh]"
            alt={training?.name}
          />
          <div className="my-5">
            <div className="my-3">
              <a href={training?.link} target="_blanc">
                <div className="flex items-center text-[17px] font-bold">
                  <FaGlobe size={25} className="mr-2" /> You can pay by clicking
                  here
                </div>
              </a>
            </div>

            <h1 className="text-[20px] font-[600] capitalize">
              Duration: {training?.duration}
            </h1>
            <h1 className="text-[20px] font-[600] capitalize">
              Date: {training?.date}
            </h1>
            <h1 className="text-[20px] font-[600] capitalize">
              Price: {training?.amount || "Free"}
            </h1>
            <h1 className="text-[20px] font-[600] capitalize">
              Address: {training?.address}
            </h1>
          </div>
        </div>
        <div className="w-[50%]">
          <h1 className="text-[20px] font-[600] text-center capitalize">
            {training?.name}
          </h1>
          <p className="text-[15px] font-[400]">{training?.body}</p>
        </div>
      </div>
    </div>
  );
};

export default TrainingDetail;
