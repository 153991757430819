import React from "react";
import { setTitle } from "../../../store/slices/navbar";
import { useDispatch, useSelector } from "react-redux";
import { FaLongArrowAltRight } from "react-icons/fa";
import { MdEmail, MdPhone } from "react-icons/md";

const Setting = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const onLoad = React.useCallback(() => {
    dispatch(setTitle("Setting"));
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onSetting = [
    { key: 1, name: "Edit profile", link: "/edit-profile" },
    { key: 2, name: "Change Password", link: "/change-password" },
    { key: 3, name: "Notification", link: "/notification" },
    { key: 4, name: "Update Company", link: "/update-company" },
    { key: 5, name: "Logout", link: "logout" },
  ];

  console.log(user);

  return (
    <div className="w-full px-3 py-5 h-full">
      <div className="flex md:justify-between h-full bg-[white] px-4 py-5">
        <div className="shadow w-[48%] rounded-2xl p-0 flex flex-col items-center py-14">
          <div className="md:py-10 flex justify-center items-center h-[200px] w-[200px] border rounded-full overflow-hidden">
            <img
              src={user?.profile?.company?.banner}
              alt="Profile pics"
              className="object-cover h-full w-full rounded-full"
            />
          </div>
          <p className="text-[#000000] my-5 text-[18px] font-bold">
            {user?.profile?.name}
          </p>

          <div className="w-[60%] border-r border-l rounded-bl-2xl rounded-br-2xl shadow">
            {onSetting.map((item) => (
              <div
                key={item.key}
                className="border-b border-l border-r rounded-bl-2xl rounded-br-2xl shadow border-t-none py-5 px-3 flex"
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>

        <div className="shadow w-[48%] rounded-2xl p-0 flex flex-col items-center py-14">
          <div className="md:py-10 flex justify-center items-center h-[200px] w-[200px] border rounded-full overflow-hidden">
            <img
              src={user?.profile?.avatar}
              alt="Profile pics"
              className="object-cover h-full w-full rounded-full"
            />
          </div>
          <p className="text-[#000000] my-5 text-[18px] font-bold">
            Corporate Setup
          </p>

          <div className="w-[60%] border bg-[white] rounded-lg shadow py-5 flex justify-center flex-col items-center">
            <div className="flex items-center justify-center text-[20px]">
              <MdPhone size={25} color="#D0D5DD" className="mx-2" />
              {user?.profile?.phone}
            </div>
            <div className="flex items-center justify-center text-[20px]">
              <MdEmail size={25} color="#D0D5DD" className="mx-2" />
              {user?.email}
            </div>
          </div>

          <div className="text-[#67C748] bg-[#D0D5DD] w-[60%] py-4 flex justify-between items-center my-5 px-3 rounded-md cursor-pointer">
            Personal Details <FaLongArrowAltRight color="#67C748" size={25} />
          </div>

          <div className="text-[#67C748] bg-[#D0D5DD] w-[60%] py-4 flex justify-between items-center my-5 px-3 rounded-md cursor-pointer">
            Company Detail <FaLongArrowAltRight color="#67C748" size={25} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
