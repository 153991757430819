import React from "react";

// navigator
import { useNavigate } from "react-router-dom";

const ServiceCard = ({ item }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/dashboard/services/" + item?.id)}
      className="w-[30%] h-[40vh] overflow-hidden rounded-tl-2xl rounded-br-2xl border my-3 cursor-pointer"
    >
      <div className="overflow-hidden w-full h-[70%] bg-[#d9d9d9]">
        <img
          src={item?.description}
          alt={item?.name}
          className="object-cover w-full h-full"
        />
      </div>
      <div className="text-[17px] text-[white] px-2 py-3 h-full font-bold capitalize pt-1 bg-[#060809] bg-opacity-60">
        {item?.name}
      </div>
    </div>
  );
};

export default ServiceCard;
