import React from "react";

// router
import { Link } from "react-router-dom";

// constants
import ButtonLink from "../../../components/ui/links/ButtonLink";
import { getstarted, service } from "../../../constants/pages";
import first from "../../../assets/images/pages/first.png";
// import first1 from "../../../assets/images/pages/first2.png";
import home1 from "../../../assets/images/pages/servicepage1.png";
// import { Divider } from "antd";

const index = () => {
  const styless = {
    borderRadius: "25px 0px",
  };
  return (
    <div className="w-full">
      <div className="w-full flex flex-items pb-20 md:relative h-[70vh]">
        <div className="flex md:justify-center md:items-center bg-bggreen md:w-[50%]">
          <div className="md:w-[70%]">
            <p className="text-3xl font-semibold text-white">OUR SERVICES</p>
            <p className="text-white my-5 ">
              Set up organisation and companies from incroporation, Website
              development, Register it with regulatory bodies, Train Staffs and
              introduce new companies to our database.
            </p>
            <ButtonLink
              title="Contact Us"
              link="/contact-us"
              className="text-bggreen bg-white hover:bg-green-800 border-white hover:border-green-800"
            />
          </div>
        </div>
        <div className="bg-white hidden md:flex md:items-center md:relative md:w-[50%] ">
          <img
            src={home1}
            alt="Hero Section"
            className="w-[95%] h-[100%] -left-4 absolute"
          />
        </div>
      </div>
      <div className="py-10 md:px-10  grid md:grid-cols-3 bg-bgash">
        {service?.ikons?.map((ikon, index) => (
          <div
            style={styless}
            key={index}
            className="max-w-[18rem] p-6 mt-8 mx-auto bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
          >
            <img className="h-12" src={ikon.img} alt={ikon.title} />
            <Link to="#">
              <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                {ikon.title}
              </h5>
            </Link>
            <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
              {ikon.content}
            </p>
          </div>
        ))}
      </div>
      <div className="px-10 py-5 mt-16">
        <p className="text-center text-3xl mb-10 font-bold">
          Get <span className="text-bggreen">started</span> with our services
          and plans.
        </p>
        <div className="min-h-[100px] py-20 px-5 md:px-16 mb-6 bg-black w-full items-center text-white md:flex md:justify-between">
          <div className="md:w-[50%]">
            <p className="font-medium text-xl">Incorporation</p>
          </div>
          <div className="md:w-[50%]">
            <p className="text-start">ALL PLANS INCLUDE:</p>
            {getstarted?.start?.map((pick) => (
              <div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content1}</p>
                </div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content2}</p>
                </div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content3}</p>
                </div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content4}</p>
                </div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content5}</p>
                </div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content6}</p>
                </div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content7}</p>
                </div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content8}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="min-h-[100px] py-20 px-5 md:px-16 mb-6 bg-black w-full items-center text-white md:flex md:justify-between">
          <div className="md:w-[50%]">
            <p className="font-medium text-xl">Retainership</p>
          </div>
          <div className="md:w-[50%]">
            <p className="text-start">ALL PLANS INCLUDE:</p>
            {getstarted?.start?.map((pick) => (
              <div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content9}</p>
                </div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content10}</p>
                </div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content11}</p>
                </div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content12}</p>
                </div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content13}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="min-h-[100px] py-20 px-5 md:px-16 mb-6 bg-black w-full items-center text-white md:flex md:justify-between">
          <div className="md:w-[50%]">
            <p className="font-medium text-xl">Document Control</p>
          </div>
          {/* <div className="h-[100%] bg-white border border-white" /> */}
          {/* <Divider orientation="center" type="horizontal" /> */}
          <div className="md:w-[50%]">
            <p className="text-start">ALL PLANS INCLUDE:</p>
            {getstarted?.start?.map((pick) => (
              <div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content14}</p>
                </div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content15}</p>
                </div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content16}</p>
                </div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content17}</p>
                </div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content18}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="min-h-[100px] py-20 px-5 md:px-16 mb-6 bg-black w-full items-center text-white md:flex md:justify-between">
          <div className="md:w-[50%]">
            <p className="font-medium text-xl">Tailor Made</p>
          </div>
          <div className="md:w-[50%]">
            <p className="text-start">ALL PLANS INCLUDE:</p>
            {getstarted?.start?.map((pick) => (
              <div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content19}</p>
                </div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content20}</p>
                </div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content21}</p>
                </div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content22}</p>
                </div>
                <div className="flex py-2 items-center">
                  <p className="mr-8">{pick.icon}</p>
                  <p>{pick.content23}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mx-auto mb-20">
          <button className="py-3 px-10 rounded-lg text-white bg-bggreen hover:bg-green-800 border-white hover:border-green-800">
            Get Started
          </button>
        </div>

        <div className="max-w-xs bg-white border mt-16 border-gray-200 rounded-sm shadow dark:bg-gray-800 dark:border-gray-700">
          <Link to="#">
            <img className="rounded-t-lg" src={first} alt="" />
          </Link>
          <div className="p-5">
            <p className="mb-4 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              Location: Lekki Phase 1 Lagos
            </p>
            <p className="mb-4 font-normal text-gray-700 dark:text-gray-400">
              Price: ₦202,000
            </p>
            <p className="text-sm mb-4">
              Bids, Tenders & Proposals (British-Nigerian Curriculum)
            </p>
            <p className="text-sm mb-4">
              The British Curriculum is one of the most well-known and...
            </p>
            <Link
              to="#"
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-bggreen rounded-lg "
            >
              Read more
              <svg
                aria-hidden="true"
                className="w-4 h-4 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                // xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
