import React from "react";

// components
import TextInput from "../../../components/elements/form/TextInput";
import Button from "../../../components/elements/form/Button";
import { Modal } from "antd";

// state
import { shareDocument } from "../../../service/api/document";
import { useDispatch, useSelector } from "react-redux";

const SendFIle = ({ document_id, visible, onSubmit }) => {
  const dispatch = useDispatch();
  const [email, emailSet] = React.useState("");
  const [error, errorSet] = React.useState("");
  const loading = useSelector(({ loading }) => loading?.update);

  const onSubmitRequest = async () => {
    if (email && document_id) {
      const response = await shareDocument(dispatch, {
        email,
        document_id,
      });
      if (response) {
        onSubmit?.();
      }
    }
  };

  return (
    <Modal
      centered
      title="Enter recipient Corporate Email"
      open={visible}
      onCancel={onSubmit}
      footer={null}
    >
      <TextInput
        name="email"
        value={email}
        onChange={(e) => {
          emailSet(e.target.value);
          errorSet("");
        }}
        status={error}
        placeholder="Email"
        type="email"
      />
      <Button
        title="Send"
        loading={loading}
        className="bg-bggreen text-[white] my-5"
        onHandler={onSubmitRequest}
      />
    </Modal>
  );
};

export default SendFIle;
