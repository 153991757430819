import {Spin} from 'antd';
import { GrCloudUpload } from "react-icons/gr";

const TextInput = ({ label, name, id, value, type, placeholder, required, status, onChange, loading, ...rest }) => {
  const borderColor = status ? 'border-[red]' : 'border-bggreen'
  return (
    <div className='w-full'>
      <label className='block font-medium'>{label}</label>
      <input
          id={id}
          type="file"
          value={value}
          name={name}
          onChange={onChange}
          placeholder={placeholder} 
          required={required}
          className='w-full !outline-none px-0 py-0 placeholder:text-gray-400 border-none focus:border-transparent bg-[white] focus:ring-0'
          hidden
          {...rest}
        />
      <div onClick={() => document.getElementById(id).click()} className={`w-full border outline-none py-5 bg-[white] cursor-pointer rounded-lg ${borderColor}`}>
        <div className='w-full flex justify-center items-center flex-col'>
          <GrCloudUpload color={value ? "#67C748" : 'black'} size={40} />
          <p className={`py-5 font-bold text-[15px] ${value ? 'text-bggreen' : 'text-[black]'}`}>Click the box to select your Document</p>
        </div>
        
        {loading && <Spin size='large' />}
      </div>
    </div>
  )
}

export default TextInput

