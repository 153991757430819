import React from "react";

// components
import { Layout } from "antd";
import NavigationBar from "../navbar";

const { Content } = Layout;

const index = ({ children }) => {
  return (
    <Layout>
      <NavigationBar />
      <Content className="bg-[#F8F8F8] h-[100%] overflow-y-auto">
        {children}
      </Content>
    </Layout>
  );
};

export default index;
