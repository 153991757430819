import axiosInstance from "../../utils/axiosInstance";

// notification
import { notify } from "../../components/alerts";

// loading
import {
  setLoading,
  stopLoading,
  setUpdate,
  stopUpdate,
} from "../../store/slices/loading";
import {
  setDocument,
  setDocuments,
  setSharedDocuments,
} from "../../store/slices/document";

export const getDocuments = async (dispatch, current_page) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/documents?page=${current_page}`);

    dispatch(setDocuments(response.data));
  } catch (err) {
    // logger action
  }
  dispatch(stopLoading());
};

export const getSharedDocuments = async (dispatch, current_page) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(
      `/shared-documents?page=${current_page}`
    );

    dispatch(setSharedDocuments(response.data));
  } catch (err) {
    // logger action
  }
  dispatch(stopLoading());
};

export const getDocument = async (dispatch, id) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/document/${id}`);

    dispatch(setDocument(response.data));
  } catch (err) {
    // logger action
  }
  dispatch(stopLoading());
};

export const shareDocument = async (dispatch, formData) => {
  let result = false;
  dispatch(setUpdate());
  try {
    await axiosInstance.post("/share-document", formData);

    notify({ type: "success", message: `File Shared with ${formData.email}.` });
    result = true;
  } catch (err) {
    // logger action
    result = false;
    notify({
      type: "success",
      message: `Please get the recipient valid email.`,
    });
  }
  dispatch(stopUpdate());
  return result;
};

export const deleteDocument = async (dispatch, id, navigate) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.get(`/delete-document/${id}`);

    notify({
      type: "success",
      message: response.data.message,
    });
    navigate(-1);
  } catch (err) {
    // logger action
    notify({
      type: "error",
      message: "This file cannot be deleted.",
    });
  }
  dispatch(stopUpdate());
};

export const uploadNewDocument = async (dispatch, formData, navigate) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post("/new-document", formData);

    notify({
      type: "success",
      message: response.data.message,
    });
    navigate(-1);
  } catch (err) {
    // logger action
    notify({
      type: "error",
      message: "This file cannot be deleted.",
    });
  }
  dispatch(stopUpdate());
};
