import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  documents: [],
  shared_documents: [],
  current_page: 1,
  last_page: 1,
  current_shared_page: 1,
  last_shared_page: 1,
  document: null,
};

export const document = createSlice({
  name: "document",
  initialState,
  reducers: {
    setDocuments: (state, action) => {
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
      state.documents = action.payload.data;
    },
    setSharedDocuments: (state, action) => {
      state.current_shared_page = action.payload.current_page;
      state.last_shared_page = action.payload.last_page;
      state.shared_documents = action.payload.data;
    },
    setDocument: (state, action) => {
      state.document = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDocuments, setSharedDocuments, setDocument } =
  document.actions;

export default document.reducer;
