import axiosInstance from "../../utils/axiosInstance";

// notification
// import { notify } from "../../components/alerts";

// loading
import { setCompany, setRCNumber } from "../../store/slices/company";
import {
  setLoading,
  stopLoading,
  setUpdate,
  stopUpdate,
} from "../../store/slices/loading";

export const getCompanyRCNumber = async (dispatch, formData) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post(
      "/auth/check-rc-number",
      formData
    );

    dispatch(setRCNumber(response.data));
  } catch (err) {}
  dispatch(stopUpdate());
};

export const updateCompany = async (dispatch, formData) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/create-join-company", formData);

    dispatch(setCompany(response.data));
    console.log("Company Update", response.data);
  } catch (err) {
    // dispatch(setMessage({ type: "error", ...err.response.data }));
  }
  dispatch(stopLoading());
};
