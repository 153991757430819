import React from "react";

// route
import { BrowserRouter, Routes, Route } from "react-router-dom";

// pages
import GuestRoutes from "./guest";
import AuthRoutes from "./auth";

export const MainRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/dashboard/*" element={<AuthRoutes />} />
        <Route path="/*" element={<GuestRoutes />} />
      </Routes>
    </BrowserRouter>
  );
};
