import React from "react";
import HeaderTitle from "../../../components/ui/title";

// state
import { get_blog } from "../../../service/api/blog";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// service
import { formatDate } from "../../../service/convert/date";

const ViewBlog = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const blog = useSelector(({ blog }) => blog.blog);

  const onLoad = React.useCallback(() => {
    get_blog(dispatch, id);
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full px-7 py-12">
      <HeaderTitle
        title={blog?.title}
        sub_title={formatDate(blog?.created_at)}
      />

      <div className="w-full h-[40vh] bg-[#d9d9d9] my-5">
        <img
          src={blog?.image}
          alt={blog?.title}
          className="w-full object-cover"
        />
      </div>

      <div className="py-7">
        <div dangerouslySetInnerHTML={{ __html: blog?.body }} />
      </div>

      <div className="w-full bg-[#d9d9d9] flex flex-col items-center justify-center py-24 rounded-2xl">
        <p className="text-[#0A1405] text-[30px] font-bold">
          Get started with us today
        </p>
        <div className="py-4">
          <button
            onClick={() => navigate("/register")}
            className="block bg-[#67C748] text-[white] px-12 py-4 rounded-xl font-bold"
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewBlog;
