import React from "react";

// state
import {
  getDocuments,
  getSharedDocuments,
} from "../../../service/api/document";
import { useDispatch, useSelector } from "react-redux";
import FileData from "../misc/FileData";
import { Link } from "react-router-dom";
import { setTitle } from "../../../store/slices/navbar";

const Documents = () => {
  const dispatch = useDispatch();
  const documents = useSelector(({ document }) => document.documents);
  const current_page = useSelector(({ document }) => document.current_page);
  const admin = useSelector(({ auth }) => auth.user?.role);
  const current_shared_page = useSelector(
    ({ document }) => document.current_shared_page
  );
  const shared_documents = useSelector(
    ({ document }) => document.shared_documents
  );

  const onLoad = React.useCallback(() => {
    dispatch(setTitle("Documents"));
    getDocuments(dispatch, current_page);
    getSharedDocuments(dispatch, current_shared_page);
  }, [current_page, current_shared_page, dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full px-10 py-10">
      {admin === "1" && (
        <div className="w-full bg-[white] text-right px-3 py-4">
          <Link
            to="/dashboard/upload-document"
            className="text-[white] bg-bggreen hover:text-[white] px-3 py-2 rounded-lg"
          >
            Upload Document
          </Link>
        </div>
      )}

      <div className="py-10 px-10 bg-[white] flex justify-center">
        <div className="w-[50%] border-r">
          <div className="text-[22px] font-bold my-4 block text-center">
            Documents
          </div>
          {documents.map((item) => (
            <FileData key={item.id} item={item} />
          ))}
        </div>
        <div className="w-[50%]">
          <div className="text-[22px] font-bold my-4 block text-center">
            Shared Documents
          </div>
          {shared_documents.map((item) => (
            <FileData key={item.id} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Documents;
