import axiosInstance from "../../utils/axiosInstance";

// loading
import { setBlogs, setBlog } from "../../store/slices/blog";
import { setLoading, stopLoading } from "../../store/slices/loading";

export const get_blogs = async (dispatch, page = 1) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-blogs?page=${page}`);

    dispatch(setBlogs(response.data));
  } catch (err) {
    // log error
  }
  dispatch(stopLoading());
};

export const get_blog = async (dispatch, id) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/view-blog/${id}`);

    dispatch(setBlog(response.data));
  } catch (err) {
    // log error
  }
  dispatch(stopLoading());
};
