import React from "react";

// components
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";

const Paginate = ({ current_page, last_page, onNext, onBack }) => {
  const onPressNext = () => {
    if (current_page < last_page) {
      onNext?.();
    }
  };

  const onPressBack = () => {
    if (current_page > 1) {
      onBack?.();
    }
  };

  return (
    <div className="w-full flex justify-end">
      <div className="flex items-center text-[20px] mx-2">
        <GrLinkPrevious
          size={20}
          color="bggreen"
          onClick={onPressBack}
          className="mx-2 cursor-pointer"
        />
        {current_page}
      </div>
      |
      <div className="flex items-center text-[20px] mx-2">
        {current_page}
        <GrLinkNext
          size={20}
          color="bggreen"
          onClick={onPressNext}
          className="mx-2 cursor-pointer"
        />
      </div>
    </div>
  );
};

export default Paginate;
