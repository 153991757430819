const terms = {
  terms: [
    {
      key: 1,
      title: "USE OF SITE AND SERVICES:",
      children: [
        {
          key: 1,
          description:
            "● Age Restriction. The Site and Services are intended for use by persons 18 years of age or older. The Site and Services are not intended for children under the age of 18. Children under the age 18 who required any of our services may use the Site and Services only with the verifiable consent of a parent or legal guardian who has agreed to be bound by these Terms and Conditions.",
        },
        {
          key: 2,
          description:
            "● Site Content Ownership. All materials and content on the Site and Services, including User Content (defined in Section 3(a) below), (collectively, the “Site Content”) are the proprietary property of us or our licensor’s (including other users of the Site), including respective copyrights, logos, slogans, trademarks, and service marks. As between you and us, you shall retain all rights in your User Content subject to these Terms and Conditions.",
        },
        {
          key: 3,
          description:
            "● Site and Services Use License. Subject to these Terms and Conditions and only to the extent expressly permitted by the functionality of the Site and Services, you are granted a limited, non-exclusive, revocable, non-assignable, and non-transferable right to use the Site and Services and access and interact with Site Content. Except with respect to your use of certain Services in accordance with their specific terms and conditions that provide for or facilitate commercial activities, you understand that the Site and Site Content is for your personal and non-commercial use only. Except as expressly permitted by these Terms and Conditions, the functionality of the Site, or your own use of your User Content (i) no Site Content may be modified, copied, distributed, framed, reproduced, republished, downloaded, displayed, posted, transmitted, sold, or otherwise used in any form or by any means, in whole or in part, and (ii) nothing herein should be construed as granting any license or right to use any Site Content, including trademarks, service marks, logos, slogans or taglines of us or third parties, or any software or code relating to the Site or Services.",
        },
        {
          key: 4,
          description:
            "● Site Downloads and shares. (I) Site downloads are private to the owners only and all share download to third party are solely the responsibility of the user (ii) You agree that: A. Site Downloads are for personal use only. B. We are not responsible for documents shared to third party (Parities) C. You may not transfer document (documents) to third party (parties) for fraudulent act. D. You may not alter, modify original documents to upload on our platform, or otherwise create a derivative work of our Site upload. E. You are responsible for protecting site downloads on your system or storage devices",
        },
        {
          key: 5,
          description: `● Abuse and Infringement. You agree that you will not use the Site or Services in any manner that is abusive, fraudulent, or unlawful, that infringes the copyright, trademark, or other intellectual property rights of any person or entity, that is inconsistent with these Terms and Conditions, or that could damage, disable, overburden or impair the Site or Services, in each case as determined in our sole discretion. Without limitation, you agree, represent, and warrant that
            (i) You will use the Site and Services in compliance with all applicable laws;
            (ii) You will not use the Site or Services to infringe the copyright, trademark, or other intellectual property rights of any person or entity;
            (iii) You will not harvest or collect email addresses or other contact information of other Site users by electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications;
            (iv) You will not use automated scripts to collect information from, access, or otherwise interact with the Site;
            (v) You will not upload, post, email, transmit or otherwise make available any content that we deem to be harmful, threatening, abusive, harassing, vulgar, obscene, hateful, or racially, ethnically, or otherwise objectionable; or manipulated or not true
            (vi) You will not impersonate any person or entity, or falsely state or otherwise misrepresent yourself or your affiliation with any person or entity;
            (vii) You will not upload, post, email, transmit or otherwise make available any unsolicited or unauthorized advertising, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other form of solicitation;
            (Viii) You will not upload, post, email, transmit, or otherwise make available any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;
            (ix) You will not use the Site or Services to solicit, advertise, provide, sell, or offer, similar or competing products or services;
            (x) You will not solicit Site users to register or sign up with another website, platform, or other service or entity;
            (xi) You will not use or attempt to use another user's account without authorization from us or create a false identity using the Site or Services;
            (xii) You will not, directly or indirectly, reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code, object code, or underlying structure, ideas, or algorithms of, or found at or through, the Site or Services or any software, documentation, or data related to the Site or Services; and
            (xiii) You will not remove any proprietary notices or labels from the Site or Services or modify, translate, or create derivative works based on the Site or Services.
            (xiiii) You will not set up fraudulent company with the aim to fraud people In the event any specific terms and conditions applicable to any particular Service conflict with these General Terms and Conditions, the specific terms and conditions shall apply.`,
        },
      ],
    },
    {
      key: 2,
      title: "REGISTRATION",
      children: [
        {
          key: 1,
          description:
            "In order to use certain parts of the Site and Services, you must register as a user and create an account on the Site. As part of the registration process, you will provide a username, password, email address, and other information and materials (the “Registration Information”) for your Site account. You may not be able to register as a user of the Site with certain third-party social networking sites (e.g., Facebook, Twitter, YouTube, etc.), we may accept or reject your user registration in our sole discretion.",
        },
        {
          key: 2,
          description:
            "You alone are responsible for maintaining the security of your Registration Information and for all uses of the Site and Services in the name of your account. Upon registration, you will have a “Dashboard” page on the Site where you can manage your use of the Site and Services (your “Dashboard”).",
        },
        {
          key: 3,
          description:
            "You represent and warrant that all Registration Information and document will be true, accurate, complete, and current and that you will promptly update your Registration Information as necessary such that it is, at all times, true, accurate, complete, and current. We may use all Registration Information in accordance with our policy",
        },
        {
          key: 4,
          description:
            "You are responsible for maintaining access to the email address associated with your account. We are unable to restore access to your account if you lose access to the email address associated with the account.",
        },
        {
          key: 5,
          description:
            "You are responsible for keeping your account active. If a user does not log in to an account for six (6) months or more from the date of the last log in, the account may be considered inactive. Inactive accounts may be permanently removed without notice.",
        },
      ],
    },
    {
      key: 3,
      title: " CONTENT SUBMITTED BY YOU",
      children: [
        {
          key: 1,
          description: `User Content.
            (i) You are solely responsible for all materials and content that are submitted by you or through your account.
            (ii) We are not responsible for any User Content that may be lost or unrecoverable through your use of the Site or Services, and you are encouraged to download and backup Content regularly and frequently.
            (iii) You must submit User Content to the Site and Services in compliance with our submission specifications. Our submission specifications are available in your Dashboard or otherwise on the Site. We reserve the right to refuse to accept and/or cause the removal of any User Content for any reason and at our sole discretion and without notice to you.
            (iv)As between you and us, and subject to our rights in these Terms and Conditions, you shall retain all rights in the User Content and nothing in these Terms and Conditions shall constitute a transfer to us of ownership of any User Content.
            (v) You hereby acknowledge and agree that we may, at our sole discretion, disclose User Content in order to: (A) comply with law enforcement, court orders, or the legal process; (B) protect the rights and safety of individuals; or (C) settle disputes over intellectual property ownership. You understand and agree that in connection with the rights granted herein, we have the right, but no obligation, to remove, monitor, market, promote, advertise, or otherwise use your User Content.`,
        },
      ],
    },
    {
      key: 4,
      title: "PAYMENTS AND REFUND POLICY",
      children: [
        {
          key: 1,
          description:
            "Service Fees: will be invoice to you with bank details to make payment, or link with be sent for card payment. CORPORATE-SETUP LTD can only start work after payment is confirm.",
        },
        {
          key: 2,
          description:
            "Payment of Service Fees. You hold the right to use any medium to make payment of invoice amount to CORPORATE-SETUP LTD.",
        },
        {
          key: 3,
          description: `CORPORATE-SETUP LTD Credit Account.
          (i) In connection with certain Services for which proceeds may be due and payable to you, we will post such proceeds in U.S. Dollars and Nigeria Naira via email with account details.`,
        },
        {
          key: 3,
          description: ` Refunds.
          (i) We do not make refund for any fund payment to our account, but you can purchase services worth that amount. We always advise to pay what is on the invoice only.`,
        },
      ],
    },
    {
      key: 5,
      title: "CONFIDENTIALITY",
      children: [
        {
          key: 1,
          description: `You acknowledge and agree that in the course of using certain Services you may become aware of certain otherwise confidential information related to our business and business practices. Except to the extent that such information is otherwise generally available to third parties or is required to be divulged by operation of law, you agree to keep such information confidential.`,
        },
      ],
    },
    {
      key: 6,
      title: " USAGE DATA",
      children: [
        {
          key: 1,
          description:
            "We may collect certain tracking and usage data generated or provided by users who visit or interact with your profile on the Site, or by users of products or other materials distributed in connection with your use of certain Services, including, but not limited to, personally identifiable information (“Usage Data”). We may provide all or part of the Usage Data to you. You agree to comply with all applicable laws with regard to your use of Usage Data. You will not transfer or disclose any Usage Data to any third party. We are not responsible for the accuracy of Usage Data, and we disclaim any and all liability relating thereto.",
        },
      ],
    },
    {
      key: 7,
      title: " TERMINATION",
      children: [
        {
          key: 1,
          description: `(a) You may terminate your use of the Site at any time. You may terminate these Terms and Conditions with respect to any Service provided you have paid all applicable Service Fees by effecting a termination from your Dashboard.
            (b) We may cancel your user registration and terminate your use of the Site or Services at any time with or without cause, in our sole discretion, and with or without notice, including for your failure to make any payment when due or your breach contract.
            (c) We may cancel your contract by sending an email to CORPORATE-SETUP.`,
        },
      ],
    },
    {
      key: 8,
      title: "GOVERNING LAW",
      children: [
        {
          key: 1,
          description:
            "These Terms and Conditions shall be governed by and construed in accordance with the laws of Nigeria, without reference to conflicts of law’s provisions and, as to matters affecting contract by Nigerian federal law. Those who use the Site or Services from other jurisdictions do so at their own volition and are responsible for compliance with local laws. In the case of any dispute parties shall first explore the opportunity of resolving the matter amicably between them. Where that fails, parties shall proceed to the Lagos Multi-Door Court House for mediation. Where the Lagos Multi-door Court House fails to re",
        },
      ],
    },
    {
      key: 9,
      title: "GOVERNING DOCUMENTS",
      children: [
        {
          key: 1,
          description:
            "Please note for government document processed by us, we are bound by the processing time, and we are not held responsible for any damage this may cause you or your company.",
        },
      ],
    },
  ],
};

export default terms;
