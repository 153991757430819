import React from "react";

// compoennts
import Navbar from "./navbar/Navbar";
import Footer from "./footer";

const GuestTheme = ({ children }) => {
  return (
    <div className="w-full block">
      <Navbar />
      {children}
      <Footer />
    </div>
  );
};

export default GuestTheme;
