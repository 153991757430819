import React from "react";
// component
import {Spin} from 'antd'

const Button = ({title, icon, loading, type, className, onHandler}) => {
  
  return (
    <button
      type={type}
      onClick={onHandler}
      disabled={loading ? true : false}
      className={`w-full justify-center items-center rounded-md md:py-3 cursor-pointer md:text-md font-semibol hover:bg-gray-300 outline-none ${className}`}
    >
      {loading && <Spin />} {icon} {title}
    </button>
  )
}

export default Button
