import React from "react";
import useUpload from "../hooks/useUpload";
import useUploadDocument from "../../hooks/useUploadDocument";

// component
import TextInput from "../../../components/elements/form/TextInput";
import SelectDate from "../../../components/elements/form/SelectDate";
import UploadInput from "../../../components/elements/form/UploadInput";
import Button from "../../../components/elements/form/Button";

// state
import { uploadNewDocument } from "../../../service/api/document";
import { useDispatch, useSelector } from "react-redux";

// navigate
import { useNavigate } from "react-router-dom";

const UploadDocument = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(({ loading }) => loading.loading);
  const { data, dataSet, error, errorSet, onValidate, onChange } = useUpload();
  const { progress, uploadDocument } = useUploadDocument();

  return (
    <div className="w-full px-10 py-10">
      <div className="py-10 px-10 bg-[white]">
        <div className="text-[25px] font-bold my-5">Upload Document</div>

        <div className="flex justify-between">
          <div className="w-[49%]">
            <TextInput
              label="Document Name"
              name="name"
              value={data.name}
              type="text"
              placeholder="Document Name"
              required={true}
              status={error.name}
              onChange={onChange}
            />
          </div>
          <div className="w-[49%]">
            <TextInput
              label="Issuing Body"
              name="issuing_body"
              value={data.issuing_body}
              type="text"
              placeholder="Issuing Body"
              required={true}
              status={error.issuing_body}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="flex justify-between my-5">
          <div className="w-[49%]">
            <SelectDate
              label="Issuing Date"
              name="issuing_date"
              value={data.issuing_date}
              type="date"
              placeholder="Issuing Date"
              required={true}
              status={error.issuing_date}
              onChange={onChange}
            />
          </div>
          <div className="w-[49%]">
            <SelectDate
              label="Expiring Date"
              name="expiring_date"
              value={data.expiring_date}
              type="date"
              placeholder="Expiring Date"
              required={true}
              status={error.expiring_date}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="flex justify-between my-5">
          <UploadInput
            id="path"
            label="Upload Document"
            value={data.path ? "Upload" : ""}
            status={error.path}
            loading={progress}
            onChange={async (e) => {
              if (e.target.files.length && e.target.files?.[0]) {
                const response = await uploadDocument(e.target.files[0]);
                if (response) {
                  dataSet((prev) => ({ ...prev, path: response }));
                  errorSet((prev) => ({ ...prev, path: "" }));
                }
              }
            }}
          />
        </div>

        <div className="flex justify-between my-5">
          <div></div>
          <div className="w-[30%]">
            <Button
              title="Submit"
              loading={loading}
              onHandler={() => {
                if (onValidate()) {
                  uploadNewDocument(dispatch, data, navigate);
                }
              }}
              className="bg-bggreen text-[white] font-bold"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDocument;
