import React from "react";
import { useNavigate } from "react-router-dom";

const Service = ({ item }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/dashboard/services/" + item?.id)}
      className="shadow-sm cursor-pointer bg-[white] border h-full w-[32%] rounded-tl-2xl rounded-br-2xl overflow-hidden"
    >
      <div className="h-[80%]">
        <img
          src={item?.description}
          alt={item.name}
          className="object-cover h-full w-full"
        />
      </div>
      <div className="w-full text-center flex justify-center items-center h-[20%]">
        {item?.name}
      </div>
    </div>
  );
};

export default Service;
