import axiosInstance from "../../utils/axiosInstance";

// notification
import { notify } from "../../components/alerts";

// loading
import { setUser } from "../../store/slices/auth";
import { setLoading, stopLoading } from "../../store/slices/loading";

export const authUser = async (formData, dispatch, navigate) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/auth/login", formData);

    const { access_token, company_status, user } = response?.data;
    localStorage.setItem("@serial", access_token);
    dispatch(setUser({ company_status, user }));
    navigate("/dashboard");
  } catch (err) {
    notify({ type: "error", message: "Username or Password Incorrect." });
  }
  dispatch(stopLoading());
};

export const registerUser = async (formData, dispatch) => {
  dispatch(setLoading());
  let result;
  try {
    const response = await axiosInstance.post("/auth/register", formData);

    notify({ type: "success", message: response?.data?.message });
    result = true;
  } catch (err) {
    const { message } = err?.response?.data;
    notify({ type: "error", message });
    result = false;
  }
  dispatch(stopLoading());
  return result;
};

export const getProfile = async (dispatch) => {
  try {
    const response = await axiosInstance.get("/get-profile");

    dispatch(setUser(response.data));
  } catch (err) {
    // logger action
  }
};
