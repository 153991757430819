import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  company: null,
  rc_number: null,
};

export const company = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    setRCNumber: (state, action) => {
      state.rc_number = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCompany, setRCNumber } = company.actions;

export default company.reducer;
