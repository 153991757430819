import React from "react";

// router
import { Link, useNavigate, useParams } from "react-router-dom";

// component
import { Popconfirm } from "antd";
import SendFIle from "../misc/SendFIle";
// import { Document, pdf } from "@react-pdf/renderer";

// service
import { compareDate } from "../../../service/convert/date";

// constants
import { MdOutlineRecycling } from "react-icons/md";
import { GrSend } from "react-icons/gr";
import { AiOutlineDownload } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
import { RiMailSendLine } from "react-icons/ri";

// state
import { getDocument, deleteDocument } from "../../../service/api/document";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../../store/slices/navbar";

const Document = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(({ loading }) => loading.update);
  const document = useSelector(({ document }) => document.document);
  const [send, sendSet] = React.useState(false);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle(document?.name));
    getDocument(dispatch, id);
  }, [dispatch, document?.name, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const expired = compareDate(document?.expiring_date);

  const confirm = () => {
    deleteDocument(dispatch, document?.id, navigate);
  };

  // console.log(document);
  return (
    <div className="w-full px-10 py-10 h-full max-h-[100vh]">
      <div className="py-10 px-10 bg-[white] h-full">
        <div className="capitalize text-[20px] font-bold my-3">
          {document?.name}
        </div>
        <div className="flex justify-center h-full">
          <div className="w-[50%] h-full overflow-hidden">
            <iframe
              title="PDF in an i-Frame"
              className="h-full"
              src={document?.path}
            />
          </div>
          <div className="w-[50%] relative">
            {/* <p className="bg-[red] px-3">Expired</p> */}

            <p className="text-[18px] capitalize">
              Issuing Body: {document?.issuing_body}
            </p>
            <p className="text-[18px] capitalize">
              Issuing Date: {document?.issuing_date}
            </p>
            <p className="text-[18px] capitalize">
              Expiring Date: {document?.expiring_date}
            </p>

            <p className="text-[30px] mt-3 font-[600]">Company</p>

            <p className="text-[18px] capitalize">
              Name: {document?.company?.company_name}
            </p>
            <p className="text-[18px]">
              Email: {document?.company?.company_email}
            </p>

            <p className="text-[18px]">
              website: {document?.company?.company_website}
            </p>

            <div className="flex justify-between my-5">
              {expired && (
                <MdOutlineRecycling size={30} className="cursor-pointer" />
              )}
              <GrSend
                size={30}
                className="cursor-pointer"
                onClick={() => sendSet(true)}
              />
              <RiMailSendLine size={30} className="cursor-pointer" />
              <Link to={document?.path} target="_blank" download>
                <AiOutlineDownload size={30} className="cursor-pointer" />
              </Link>
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={confirm}
                onCancel={() => null}
                okButtonProps={{
                  loading,
                  className: "text-[black] border hover:bg-bggreen",
                }}
                okText="Yes"
                cancelText="No"
              >
                <FiTrash
                  size={30}
                  className="cursor-pointer"
                  // onClick={}
                />
              </Popconfirm>
            </div>
          </div>
        </div>
      </div>
      <SendFIle
        document_id={document?.id}
        visible={send}
        onSubmit={() => sendSet(false)}
      />
    </div>
  );
};

export default Document;
