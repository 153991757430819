import React from "react";
import useLogin from "../hooks/useLogin";

// router dom
import { Link } from "react-router-dom";
import Button from "../../../components/elements/form/Button";

// component
import { Divider } from "antd";

// constants
import logo from "../../../assets/images/icons/logo.png";
// import google from "../../../assets/images/icons/logo.png";
import TextInput from "../../../components/elements/form/TextInput";
import { useSelector } from "react-redux";

const Login = () => {
  const loading = useSelector(({ loading }) => loading.loading);
  const { data, onChange, onSubmit } = useLogin();

  return (
    <div className="object-center">
      <div className="flex min-h-full py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md mx-auto space-y-8">
          <div>
            <img className="h-24" src={logo} alt="Your Company" />
            <h6 className="text-xl font-bold tracking-tight text-gray-900">
              Welcome to Corporate Set Up!
            </h6>
            <p className="mt-2 text-sm text-gray-600">
              Don’t have an account yet?
              <Link
                to="/register"
                className="font-medium text-bggreen hover:text-midnight-200"
              >
                {" "}
                Sign up
              </Link>
            </p>
          </div>
          <form
            className="space-y-6 relative"
            onSubmit={onSubmit}
            action="#"
            method="POST"
          >
            {/* <input type="hidden" name="remember" value="true" /> */}

            <TextInput
              type="text"
              label="Email"
              name="email"
              value={data.email}
              placeholder="Email"
              required={true}
              onChange={onChange}
            />

            <TextInput
              type="password"
              label="Password"
              name="password"
              value={data.password}
              placeholder="Password"
              required={true}
              onChange={onChange}
            />

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-bggreen focus:ring-bggreen"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <Link
                  href="#"
                  className="font-medium text-bggreen hover:text-bggreen"
                >
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div>
              <Button
                title="Sign In"
                type="submit"
                loading={loading}
                onHandler={() => null}
                className="bg-bggreen text-white"
              />
            </div>
          </form>
          <Divider>Or</Divider>
          <Button
            type="button"
            title="Sign Up with Google"
            className="bg-[white] border text-[black]"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
