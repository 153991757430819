import React from "react";

// constants
import dashboard from "../../../constants/dashboard";

// router
import { useNavigate } from "react-router-dom";

// state
import { get_services } from "../../../service/api/service";
import { useDispatch, useSelector } from "react-redux";
import Service from "./Service";

const RegisteredUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const services = useSelector(({ service }) => service.services?.slice(0, 3));

  const onLoad = React.useCallback(() => {
    get_services(dispatch, 1);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="flex items-center flex-col h-full">
      <div className="flex w-[70%] h-[40%] overflow-hidden justify-between items-center px-2 py-3 border rounded-xl shadow-lg bg-[white]">
        {dashboard.dasboard.map((item) => (
          <div
            onClick={() => navigate(item.link)}
            className="w-[50%] h-full cursor-pointer"
            key={item.key}
          >
            {/* <Link to={item.link}> */}
            <div className="h-[80%] w-full">
              <img
                src={item.icon}
                alt="document"
                className="w-full object-contain h-full"
              />
            </div>
            <p className="text-center font-bold text-[20px]">{item.label}</p>
            {/* </Link> */}
          </div>
        ))}
      </div>

      <div className="flex overflow-y-hidden overflow-x-scroll justify-start items-center w-full my-3 py-3">
        {dashboard.links.map((item, _) => (
          // <Link to={item.link}>
          <div
            key={_}
            onClick={() => navigate(item.link)}
            className="text-[20px] mx-2 bg-bggreen px-5 rounded-lg py-2 text-[white] cursor-pointer"
          >
            {item.name}
          </div>
        ))}
      </div>

      <div className="w-full flex flex-row overflow-x-auto justify-between h-full">
        {services?.map((item) => (
          <Service key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
};

export default RegisteredUser;
