import React from "react";

const HeaderTitle = ({ title, sub_title }) => {
  return (
    <div className="w-full text-center py-5 block">
      <div className="text-[0A1405] text-[24px] font-bold">{title}</div>
      <div className="text-[gray] text-[15px]">{sub_title}</div>
    </div>
  );
};

export default HeaderTitle;
