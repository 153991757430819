import React from "react";
import useCompany from "../hooks/useCompany";

// components
import TextInput from "../../../components/elements/form/TextInput";
import SelectInput from "../../../components/elements/form/SelectInput";
import Button from "../../../components/elements/form/Button";

// state
import { getCountries, getStates } from "../../../service/api/misc";
import {
  getCompanyRCNumber,
  updateCompany,
} from "../../../service/api/company";
import { useDispatch, useSelector } from "react-redux";

const Company = () => {
  const dispatch = useDispatch();
  const update = useSelector(({ loading }) => loading.update);
  const loading = useSelector(({ loading }) => loading.loading);
  const countries = useSelector(({ misc }) => misc.countries);
  const states = useSelector(({ misc }) => misc.states);
  const { data, error, onChange, onValidate } = useCompany();

  const onSubmit = () => {
    if (onValidate()) {
      updateCompany(dispatch, data);
    }
  };

  const onLoad = React.useCallback(() => {
    getCountries(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="flex flex-col justify-center items-center h-[93%] bg-[white] my-5 mx-3 px-10">
      <div className="w-full text-left">
        <span className="block text-[25px] font-[500] text-[#000]">
          Join your company.
        </span>
        <span className="block text-[18px] font-[500] text-[#000]">
          Let us sync you with your company
        </span>
      </div>

      <div className="flex w-full justify-between my-2">
        <div className="w-[49%]">
          <TextInput
            label="RC Number"
            name="rc_number"
            value={data.rc_number}
            onChange={onChange}
            onBlur={() =>
              getCompanyRCNumber(dispatch, { rc_number: data.rc_number })
            }
            placeholder="RC Number"
            required={true}
            status={error.rc_number}
            loading={update}
          />
        </div>
        <div className="w-[49%]">
          <TextInput
            label="Company Name"
            name="company_name"
            value={data.company_name}
            onChange={onChange}
            placeholder="Company Name"
            required={true}
            status={error.company_name}
          />
        </div>
      </div>

      <div className="flex w-full justify-between my-2">
        <div className="w-[49%]">
          <TextInput
            label="Company Phone"
            name="company_phone"
            value={data.company_phone}
            onChange={onChange}
            placeholder="Company Phone"
            required={true}
            status={error.company_phone}
          />
        </div>
        <div className="w-[49%]">
          <TextInput
            label="Company Email"
            name="company_email"
            value={data.company_email}
            onChange={onChange}
            placeholder="Company Email"
            required={true}
            status={error.company_email}
          />
        </div>
      </div>

      <div className="flex w-full justify-between my-2">
        <div className="w-[49%]">
          <TextInput
            label="Company Website"
            name="company_address"
            value={data.company_address}
            onChange={onChange}
            placeholder="Company Website"
            required={true}
            status={error.company_address}
          />
        </div>
        <div className="w-[49%]">
          <TextInput
            label="Company Website"
            name="company_website"
            value={data.company_website}
            onChange={onChange}
            placeholder="Company Website"
            required={true}
            status={error.company_website}
          />
        </div>
      </div>

      <div className="flex w-full justify-between my-2">
        <div className="w-[49%]">
          <SelectInput
            label="Company Country"
            items={countries}
            name="company_country"
            value={data.company_country}
            onChange={(e) => {
              getStates(e?.target?.value, dispatch);
              onChange(e);
            }}
            placeholder="Company Country"
            required={true}
            status={error.company_country}
          />
        </div>
        <div className="w-[49%]">
          <SelectInput
            label="Company State"
            items={states}
            name="company_state"
            value={data.company_state}
            onChange={onChange}
            placeholder="Company State"
            required={true}
            status={error.company_state}
          />
        </div>
      </div>

      <div className="w-[50%] my-10">
        <Button
          title="Submit"
          loading={loading}
          onHandler={onSubmit}
          className="bg-bggreen text-[white]"
        />
      </div>
    </div>
  );
};

export default Company;
