import React from "react";

// components
import TrainingCard from "../misc/TrainingCard";

// state
import { useDispatch, useSelector } from "react-redux";
import { getTrainings } from "../../../service/api/training";
import HeaderTitle from "../../../components/ui/title";
import Paginate from "../../../components/ui/paginate";

const Training = () => {
  const dispatch = useDispatch();
  const last_page = useSelector(({ opportunity }) => opportunity.last_page);
  const current_page = useSelector(({ training }) => training.current_page);
  const trainings = useSelector(({ training }) => training.trainings);

  const onLoad = React.useCallback(() => {
    getTrainings(dispatch, current_page);
  }, [current_page, dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full px-3 py-5 h-full">
      <HeaderTitle
        title="Corporate Setup Trainings"
        sub_title="All you need know about our training programs."
      />
      <div className="flex flex-wrap justify-between h-full bg-[white] px-4 py-5">
        {trainings.map((item) => (
          <TrainingCard key={item.id} item={item} />
        ))}
      </div>

      <Paginate
        current_page={current_page}
        last_page={last_page}
        onBack={() => null}
        onNext={() => null}
      />
    </div>
  );
};

export default Training;
