import React from 'react';
import { Link } from 'react-router-dom'

// components
import ButtonLink from '../../../components/ui/links/ButtonLink';

// constants
import Nav from '../../../constants/nav';
import {IoMenuOutline, IoCloseSharp} from 'react-icons/io5'
import logo from "../../../assets/images/icons/logo.png";

const Navbar = () => {
  const [open, setOpen] = React.useState(false)
  return (
    <div className='shadow-md w-full font-abel bg-[#FFFFFF] border-b md:border-b bg-gradient-to-r from-bggreen from-0% to-[white] to-50% py-2 px-2 '>
      <div className='flex w-full items-center '>
        <div className='flex w-full md:w-4/12 items-center justify-between'>
          <div>
            <Link to="/">
              <img src={logo} className="h-[50px] md:h-[60px] object-contain sm:h-9 cursor-pointer" alt="icon" />
            </Link>
          </div>
          <div onClick={() => setOpen(!open)} className='text-3xl md:hidden cursor-pointer'>
            {open ? <IoCloseSharp /> : <IoMenuOutline />}
          </div>
        </div>

        <ul className={`md:flex w-[50%] bg-[white] md:bg-transparent border-t-0 border-[#000] md:border-none md:justify-end text-left md:w-full md:items-center md:static md:pb-0 rounded-b-lg right-2 absolute md:text-right transition-all duration-500 ease-in font-bold ${open ? 'top-20' : 'top-[-700px]'}`}>
          {Nav?.map((item) =>
            <li className='md:ml-8 text-lg md:my-0 my-7 w-full text-center' key={item.title}>
              <Link to={item.link} className="hover:text-gray-400 text-[#4E4E4E] duration-500">{item.title}</Link>
            </li>
          )}

          <div className='flex flex-col md:flex-row md:visible my-3 md:my-0 px-5 md:justify-center md:items-center'>
            <ButtonLink title="Login" link="/login" className="text-bggreen border-bggreen md:my-0 my-2 md:mr-2" />
            <ButtonLink title="Register" link="/register" className="text-[white] bg-bggreen font-medium border-bggreen my-2 md:mr-3" />
          </div> 
        </ul>
      </div>
    </div>
  )
}

export default Navbar
