import {Spin} from 'antd';

const SelectInput = ({ label, items, name, status, onChange, icon, loading }) => {
  const borderColor = status ? 'border-[red]' : 'border-bggreen'
  return (
    <div className='w-full'>
      <label className='block font-medium'>{label}</label>
      <div className={`flex w-full border items-center outline-none mt-3 text-gray-900 px-3 py-2 focus:shadow-sm bg-[white] ${borderColor}`}>
        {icon && <div className='mr-1 border-r pr-2'>{icon}</div>}
        <select onChange={onChange} name={name} className='px-0 py-0 border-none !outline-none focus:border-none w-full bg-[white] focus:ring-0'>
          <option value="">Please Select</option>
          {items.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
        </select>
        {loading && <Spin size='small' />}
      </div>
    </div>
  )
}

export default SelectInput

