import React from "react";

// router link
import { useNavigate } from "react-router-dom";

const ButtonLink = ({ title, link, className }) => {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      onClick={() => navigate(link)}
      className={`hover:text-white py-[10px] border outline-none font-medium rounded-lg w-full text-sm md:px-[40px] md:py-2.5 text-center ${className}`}
    >
      {title}
    </button>
  );
};

export default ButtonLink;
