import document from "../assets/images/nav_icons/document.png";
import dashboard from "../assets/images/nav_icons/dashboard.png";
import opportunity from "../assets/images/nav_icons/opportunity.png";
import training from "../assets/images/nav_icons/training.png";
import notification from "../assets/images/nav_icons/notification.png";
import settings from "../assets/images/nav_icons/settings.png";
import help from "../assets/images/nav_icons/help.png";

// components

const sidebar = {
  nav_links: [
    {
      label: "Dashboard",
      key: "1",
      icon: dashboard,
      link: "/dashboard",
    },
    {
      label: "Document",
      key: "2",
      icon: document,
      link: "/dashboard/documents",
    },
    {
      label: "Opportunity",
      key: "3",
      icon: opportunity,
      link: "/dashboard/opportunity",
    },
    {
      label: "Services",
      key: "4",
      icon: training,
      link: "/dashboard/services",
    },
    {
      label: "Notification",
      key: "5",
      icon: notification,
      link: "/dashboard/notifications",
    },
  ],
  settings: [
    {
      label: "Settings",
      key: "6",
      icon: settings,
      link: "/dashboard/settings",
    },
    {
      label: "Help Center",
      key: "7",
      icon: help,
      link: "/help-center",
    },
  ],
};
export default sidebar;
