import React from "react";

// components
import Button from "../../../components/elements/form/Button";
import { Modal } from "antd";

// constant
import success from "../../../assets/images/alerts/success.png";
import warning from "../../../assets/images/alerts/warning.png";

// state
import { clearMessage } from "../../../store/slices/message";
import { useDispatch, useSelector } from "react-redux";

const SweetAlert = () => {
  const dispatch = useDispatch();
  const message = useSelector(({ message }) => message.message);
  const onClear = React.useCallback(() => {
    if (message !== null) {
      setTimeout(() => {
        dispatch(clearMessage());
      }, 5000);
    }
  }, [dispatch, message]);

  React.useEffect(() => {
    onClear();
  }, [onClear]);

  return (
    <Modal
      open={message != null ? true : false}
      centered
      footer={null}
      closable={false}
    >
      <div className="text-center flex flex-col items-center">
        <div className="w-full flex justify-center h-[20vh] overflow-hidden">
          <img
            src={message?.type === "success" ? success : warning}
            alt="notification"
            className="obeject-cover h-[full] w-[30%]"
          />
        </div>
        <p
          className={`text-[30px] ${
            message?.type === "success" ? "text-bggreen" : "text-[red]"
          }`}
        >
          {message?.message}
        </p>
        <div className="w-[50%] ">
          <Button
            title="Ok"
            type="button"
            onHandler={() => dispatch(clearMessage())}
            className="bg-bggreen text-[white] font-bold text-[20px]"
          />
        </div>
      </div>
    </Modal>
  );
};

export default SweetAlert;
