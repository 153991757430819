import React from "react";

// services
import { formatDate } from "../../../service/convert/date";

// react router dom
import { Link } from "react-router-dom";

const TrainingCard = ({ item }) => {
  return (
    <div className="w-[32%] h-[55vh] overflow-hidden rounded-br-3xl rounded-tl-3xl">
      <Link to={`/blog/${item?.id}`}>
        <div className="bg-[#d9d9d9] h-[60%]">
          <img
            src={item?.image}
            alt={item?.title}
            className="object-cover h-full w-full"
          />
        </div>
        <div className="bg-[#060809] bg-opacity-70 h-[40%] flex flex-col justify-between px-5 py-5 text-[white]">
          <p className="font-bold text-[20px]">{item?.title}</p>
          <p className="text-[15px]">{formatDate(item?.created_at)}</p>
        </div>
      </Link>
    </div>
  );
};

export default TrainingCard;
