import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: null,
};

export const navbar = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTitle } = navbar.actions;

export default navbar.reducer;
