export const formatDate = (date) => {
  const newDate = new Date(date).toISOString();
  return new Date(newDate).toDateString();
};

export const compareDate = (date) => {
  const todays_date = new Date("2022-05-12");
  const formatted_date = new Date(date);
  return todays_date > formatted_date;
};
