import React from "react";

// router
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../service/convert/date";

const TrainingCard = ({ item }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/training/" + item?.id)}
      className="w-[32%] h-[60vh] overflow-hidden rounded-tl-2xl rounded-br-2xl border cursor-pointer"
    >
      <div className="overflow-hidden h-[65%] bg-[#d9d9d9]">
        <img
          src={item?.image}
          alt={item.name}
          className="object-cover h-[100%]"
        />
      </div>
      <div className="text-[white] py-3 px-4 font-bold bg-[#060809] bg-opacity-70 text-[15px] h-[35%] capitalize flex flex-col justify-between">
        <p>{item?.name}</p>
        <p>{item?.amount}</p>
        <p>{formatDate(item?.date)}</p>
      </div>
    </div>
  );
};

export default TrainingCard;
