import React from "react";
import { our, choose } from "../../../constants/pages";
import ButtonLink from "../../../components/ui/links/ButtonLink";
import home1 from "../../../assets/images/pages/aboutus1.png";
import home2 from "../../../assets/images/pages/aboutus2.png";

const AboutUs = () => {
  return (
    <div className="w-full">
      <div className="w-full flex flex-items md:relative h-[60vh]">
        <div className="flex md:justify-center md:items-center bg-bggreen md:w-[50%]">
          <div className="md:w-[70%]">
            <p className="text-3xl font-semibold text-white">ABOUT US</p>
            <p className="text-white my-6">
              We set up organisation and companies from incorporation, Website
              development, Register it with regulatory bodies, Train Staffs to
              achieve its corporate objectives.
            </p>
            <ButtonLink
              title="Contact Us"
              link="/contact-us"
              className="text-bggreen bg-white hover:bg-green-800 border-white hover:border-green-800"
            />
          </div>
        </div>
        <div className="bg-white hidden md:flex md:items-center md:relative md:w-[50%]">
          <img
            src={home1}
            alt="Hero Section"
            className="w-[95%] h-[100%] -left-4 md:absolute"
          />
        </div>
      </div>

      <div className="w-[90%] flex md:-right-[85px] md:bottom-[25px] md:relative h-[80vh]">
        <div className="bg-white hidden md:flex md:items-center md:relative md:w-[40%]">
          <img
            src={home2}
            alt="Hero Section"
            className="w-[100%] h-[100%]  absolute"
          />
        </div>
        <div className="flex md:justify-center md:items-center bg-bgash md:w-[60%]">
          <div className="md:w-[70%] text-center">
            <p className="text-3xl font-bold text-black">Who We Are</p>
            <p className="text-black text-center font-semibold my-10">
              Corporate-Setup: Set up organisation and companies from
              incroporated, Website development, Register it with regulatory
              bodies, Train Staffs and introduce new companies to our database.
              We also created web platform that enable our customer set up their
              organization and monitor it progress from the comfort of their
              homes or office, the platform also remind our customers when
              compliance document is about to be expire, allow them download it
              from their profile when it's ready and also allow them share
              documents with third party who required it via email. Also we
              manage regulatory bodies registration and renewal of it licences
              for companies who have sign retainership with us, the also enjoy
              professional advice and representation in bidding for contract.
            </p>
            <ButtonLink
              title="Get Started Now"
              link="/"
              className="text-white px-24 bg-bggreen hover:bg-green-800 border-white hover:border-green-800"
            />
          </div>
        </div>
      </div>
      <div className="px-10 py-10 justify-around grid md:grid-cols-3 ">
        {/* ------------------About Us Page Section 1--------------- */}
        {our?.ours?.map((goals, index) => (
          <div className="px-6 max-w-[250px]" key={index}>
            <div className="flex items-center space-x-2">
              <div className="h-3 w-6 bg-bggreen"></div>
              <p className="text-xl font-bold">{goals.title}</p>
            </div>
            <p>{goals.content}</p>
          </div>
        ))}
      </div>
      <div className="items-center text-center bg-bgash py-20">
        <p className="text-3xl font-bold">Why Choose Us?</p>
        <p className="text-2xl">We deliver the high quality end results you need.</p>

        <div className="px-10 py-10 w-full grid md:grid-cols-2 justify-around">
         {/* ------------------About Us Page Section 2--------------- */}
          {choose?.reasons?.map((reason) => (
            <div className="flex mx-auto mt-10">
              <p className="text-bggreen text-2xl">{reason.number}</p>
              {/* <p>{reason.icons}</p> */}
              <div className="items-center px-1 ">
                <p className="text-lg font-bold">{reason.title}</p>
                <p className="text-sm my-1.5">{reason.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
