import React from "react";

// components
import ServiceCard from "../misc/ServiceCard";
import Paginate from "../../../components/ui/paginate";

// state and service
import { get_services } from "../../../service/api/service";
import { onBack, onNext } from "../../../store/slices/service";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../../store/slices/navbar";

const ServiceDashboard = () => {
  const dispatch = useDispatch();
  const current_page = useSelector(({ service }) => service.current_page);
  const last_page = useSelector(({ service }) => service.last_page);
  const services = useSelector(({ service }) => service.services);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle("Service"));
    get_services(dispatch, current_page);
  }, [current_page, dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full px-3 py-5 h-full items-center">
      <div className="flex flex-col h-full bg-[white] px-4 py-5">
        <div className="flex flex-wrap justify-between h-full bg-[white] overflow-y-auto">
          {services.map((item) => (
            <ServiceCard key={item.id} item={item} />
          ))}
        </div>
        <Paginate
          current_page={current_page}
          last_page={last_page}
          onBack={() => dispatch(onBack())}
          onNext={() => dispatch(onNext())}
        />
      </div>
    </div>
  );
};

export default ServiceDashboard;
