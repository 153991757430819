import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  update: false,
  show: false,
  delete: false,
};

export const loading = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    setUpdate: (state) => {
      state.update = true;
    },
    stopUpdate: (state) => {
      state.update = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, stopLoading, setUpdate, stopUpdate } =
  loading.actions;

export default loading.reducer;
