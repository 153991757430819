import axiosInstance from "../../utils/axiosInstance";

// notification
// import { notify } from "../../components/alerts";

// loading
import { setLoading, stopLoading } from "../../store/slices/loading";
import { setTraining, setTrainings } from "../../store/slices/training";

export const getTrainings = async (dispatch, current_page) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/trainings?page=${current_page}`);

    dispatch(setTrainings(response.data));
  } catch (err) {
    // logger action
  }
  dispatch(stopLoading());
};

export const getTraining = async (dispatch, id) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/trainings/${id}`);

    dispatch(setTraining(response.data));
  } catch (err) {
    // logger action
  }
  dispatch(stopLoading());
};
