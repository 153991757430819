import axiosInstance from "../../utils/axiosInstance";

// loading
import { setCountries, setStates } from "../../store/slices/misc";

export const getCountries = async (dispatch) => {
  try {
    const response = await axiosInstance.get("/auth/get-country");

    dispatch(setCountries(response.data));
  } catch (err) {}
};

export const getStates = async (id, dispatch) => {
  try {
    const response = await axiosInstance.get(`/auth/get-states/${id}`);

    dispatch(setStates(response.data));
  } catch (err) {}
};
