const constants = {
  nav: [
    {
      title: "COMPANY",
      subtitle: [
        { title: "Opportunities", link: "#" },
        { title: "Faq", link: "/faq" },
        { title: "Support", link: "#" },
      ],
    },
    {
      title: "ABOUT",
      subtitle: [
        { title: "Service", link: "#" },
        { title: "About Us", link: "#" },
        { title: "Blog", link: "#" },
      ],
    },
    {
      title: "LEGAL",
      subtitle: [
        { title: "Terms & Conditions", link: "#" },
        { title: "Privacy Policy", link: "#" },
        { title: "Cookies", link: "#" },
      ],
    },
    {
      title: "FOLLOW",
      subtitle: [
        { title: "Twitter", link: "#" },
        { title: "Instagram", link: "#" },
        { title: "Facebook", link: "#" },
      ],
    },
    {
      title: "CONTACT",
      subtitle: [
        { title: "Setup@corporate-setup.com", link: "#" },
        { title: "Trainings@corporate-setup.com", link: "#" },
      ],
    },
  ],
};

export default constants;
