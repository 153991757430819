import React from "react";

// constants
import { AiFillFileText } from "react-icons/ai";
import { compareDate } from "../../../service/convert/date";
import { useNavigate } from "react-router-dom";

const FileData = ({ item }) => {
  // console.log(item);
  const expired = compareDate(item?.expiring_date);
  const navigate = useNavigate();

  return (
    // <Link to={`/dashboard/document/${item?.id}`}>
    <div
      onClick={() => navigate("/dashboard/document/" + item?.id)}
      className="flex justify-between items-center mx-3 my-2 border border-bggreen rounded-lg px-4 py-3 cursor-pointer"
    >
      <div className={`text-[18px] ${expired ? "text-[red]" : "text-bggreen"}`}>
        {item?.name}
      </div>
      <div>
        <AiFillFileText
          size={25}
          color={expired ? "black" : "#67C748"}
          className={`expired ? "bg-[red]" : "bg-bggreen"`}
        />
      </div>
    </div>
    // </Link>
  );
};

export default FileData;
