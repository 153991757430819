import axiosInstance from "../../utils/axiosInstance";

// notification
// import { notify } from "../../components/alerts";

// loading
import { setService, setServices } from "../../store/slices/service";
import { setMessage } from "../../store/slices/message";
import { setLoading, stopLoading } from "../../store/slices/loading";

export const get_services = async (dispatch, current_page) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(
      `/get-services?page=${current_page}`
    );

    dispatch(setServices(response.data));
  } catch (err) {
    // logger action
  }
  dispatch(stopLoading());
};

export const get_service = async (dispatch, id) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-service/${id}`);

    dispatch(setService(response.data));
  } catch (err) {
    // logger action
  }
  dispatch(stopLoading());
};

export const request_service = async (dispatch, formData) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/request-services", formData);

    dispatch(setMessage({ type: "success", message: response.data?.message }));
  } catch (err) {
    dispatch(
      setMessage({ type: "success", message: err?.response.data?.message })
    );
    // logger action
  }
  dispatch(stopLoading());
};
