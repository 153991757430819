// constants
import React from "react";
import ButtonLink from "../../../components/ui/links/ButtonLink";
import { constant } from "../../../constants/pages";
import home1 from "../../../assets/images/pages/corporatehome1.png";
import home2 from "../../../assets/images/pages/corporatehome2.png";
import icon1 from "../../../assets/images/pages/Manufacturing.png";
import icon2 from "../../../assets/images/pages/Auction.png";
import icon3 from "../../../assets/images/pages/Strength.png";
import oil from "../../../assets/images/pages/oil.png";

const Landing = () => {
  const style = {
    width: "100%",
    // height: "100vh";
    background: `linear-gradient(
      to right,
      #67C748 0%,
      #67C748 12%,
      white 12%,
      white 100%
    )`,
  };
  return (
    <div className="w-full">
      <div className="w-full flex flex-items md:relative h-[60vh]">
        <div className="flex md:justify-center md:items-center bg-bggreen md:w-[50%]">
          <div className="md:w-[70%]">
            <p className="text-3xl font-semibold text-white">CORPORATE SETUP</p>
            <p className="text-white my-5">
              We set up organisation and companies from incorporation, Website
              development, Register it with regulatory bodies, Train Staffs to
              achieve its corporate objectives.
            </p>
            <ButtonLink
              title="Contact Us"
              link="/contact-us"
              className="text-bggreen bg-white hover:bg-green-800 border-white hover:border-green-800"
            />
          </div>
        </div>
        <div className="bg-white hidden md:flex md:items-center md:relative md:w-[50%]">
          <img
            src={home1}
            alt="Hero Section"
            className="w-[90%] h-[85%] -left-4 absolute"
          />
        </div>
      </div>

      <div className="items-center justify-center w-1/2 text-center py-20">
        <p className="text-3xl">We Set up Just As You Like It</p>
        <p>
          Behold the power to set up your company from anywhere and monitor its
          progress from anywhere..
        </p>
      </div>
      <div className="w-full px-10 pb-20 ">
        <div className="bg-bggreen flex-items  justify-between px-20 py-8 grid md:grid-cols-3">
          <div className="items-center justify-items-center border-r-2 border-[white]">
            <img className="mx-auto" src={icon1} alt="Incorporation" />
            <p className="text-2xl text-center text-white">Incorporation</p>
          </div>
          <div className="items-center justify-items-center border-r-2 border-[white] ">
            <img className="mx-auto" src={icon2} alt="Regulatory Bodies" />
            <p className="text-2xl text-center text-white">Regulatory Bodies</p>
          </div>
          <div className="items-center justify-items-center ">
            <img className="mx-auto" src={icon3} alt="Professional Bidder" />
            <p className="text-2xl text-center text-white">
              Professional Bidder
            </p>
          </div>
        </div>
      </div>
      <div className="w-full justify-between grid md:grid-cols-2 px-10">
        <div className=" ">
          <div>
            <p className="text-black text-3xl font-medium">
              WHY CHOOSE <span className="text-bggreen">US?</span>{" "}
            </p>
            <p className="text-black text-2xl font-medium my-3 w-3/4">
              We deliver the high quality end results you need.
            </p>
          </div>
          <div>
            {constant.whys.map((why) => (
              <div
                className=" flex flex-items bg-white shadow-md mt-2"
                key={why.title}
                style={style}
              >
                <img alt={why.title} src={why.img} className="mr-5" />
                <div className="block">
                  <p className="text-lg font-medium">{why.title}</p>
                  <p className="text-base">{why.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <img src={home2} className="h-3/5" alt="home 2" />
        </div>
      </div>
      <div className="full flex h-[100vh]">
        <div className="overlay w-2/5  bg-cover relative bg-bggreen bg-opacity-50">
          <img
            src={oil}
            alt="oil"
            className="object-cover absolute mix-blend-overlay h-full w-full"
          />
          <div className="px-16 pt-40">
            <h2 className="text-5xl py-5">
              Our <span className="text-white">Services</span>
            </h2>
            <p className="text-white text-2xl leading-8">
              Set up organisation and companies from incroporation, Website
              development, Register it with regulatory bodies, Train Staffs and
              introduce new companies to our database.
            </p>
            <div className="w-2/5 py-10">
              <ButtonLink
                title="View more"
                link="/"
                className="text-bggreen bg-white hover:bg-green-800 border-white hover:border-green-800"
              />
            </div>
          </div>
        </div>
        <div className="w-3/5 bg-bgash"></div>
      </div>
      {/* Testimonial */}
      <div className="w-full bg-bggreen px-10 py-20">
        <div className="bg-white p-5 h-2/5 w-1/4 my-5">
          <p>CEO Evoke</p>
          <p>
            Am a Nigerian base in US I have never seen them, but the did my
            NIPEX registration, from start to finish, I was carried along like a
            baby. I pay them monthly.
          </p>
        </div>
        <div className="h-1/5 w-1/6 bg-white">
          <button>button</button>
        </div>
        <div className="bg-white p-5 h-2/5 w-1/4 my-5">
          <p>CEO Janelen</p>
          <p>
            It’s still looks like miracle to me, all document was brought to my
            house by a staff(Lawyer), wow!!!
          </p>
        </div>
        <div className="h-1/5 w-1/6 bg-white">
          <button>button</button>
        </div>
        <div className="bg-white p-5 h-2/5 w-1/4 my-5">
          <p>CEO Madera</p>
          <p>
            I was on WhatsApp chatting till I completed all my registration like
            magic, the comfort is amazing.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Landing;
