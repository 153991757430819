import React from "react";

// router
import { useNavigate } from "react-router-dom";

const ServiceLargeCard = ({ title, sub_title, button_title, link }) => {
  const navigate = useNavigate();

  return (
    <div className="w-[45%] bg-[white] flex justify-between flex-col rounded-xl overflow-hidden px-3 py-5">
      <h1 className="text-center text-[20px] font-[500] my-2">{title}</h1>
      <h1 className="text-center text-[15px] font-[300] my-3">{sub_title}</h1>
      <button
        className="bg-bggreen rounded-md text-[white] py-2 my-3 font-[500]"
        onClick={() => navigate(link)}
      >
        {button_title}
      </button>
    </div>
  );
};

export default ServiceLargeCard;
