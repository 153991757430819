import React from "react";

const CheckBox = ({ name, value, label, onChange, status }) => {
  const color = status ? "text-[red]" : "text-gray-900";
  return (
    <div className="flex items-center">
      <input
        id={name}
        name={name}
        onChange={onChange}
        type="checkbox"
        value={value}
        className="h-4 w-4 !outline-none rounded border-gray-300 text-bggreen focus:ring-bggreen"
      />
      <label htmlFor={name} className={`ml-2 block text-sm ${color}`}>
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
