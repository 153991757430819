import React from "react";

// components
import NewLetterInput from "../../../components/elements/form/NewLetterInput";
import { Divider } from "antd";

// link router
import { Link } from "react-router-dom";

// constanst
import constants from "../../../constants/footer";

import foot1 from "../../../assets/images/statics/foot1.png";
import foot2 from "../../../assets/images/statics/foot2.png";
import foot3 from "../../../assets/images/statics/foot3.png";
import foot4 from "../../../assets/images/statics/foot4.png";
import ios from "../../../assets/images/statics/Apple.png";
import android from "../../../assets/images/statics/Android.png";
import logo from "../../../assets/images/icons/logo.png";
import { Bounce, Zoom } from "react-reveal";

const Footer = () => {
  const [email, emailSet] = React.useState("");
  return (
    <footer className="bg-black dark:bg-gray-900 w-full">
      <div className="w-full md:grid grid-cols-2 gap-5 px-4 py-8 md:grid-cols-5">
        {constants.nav.map((item) => (
          <div className="w-full" key={item.title}>
            <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-gray-400">
              <Bounce left>{item.title}</Bounce>
            </h2>
            <ul className="text-gray-500 dark:text-gray-400">
              {item.subtitle.map((data) => (
                <li className="mb-3" key={data.title}>
                  <Link to={data.link} className="hover:text-white">
                    <Zoom bottom>{data.title}</Zoom>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="text-white ">
        <Divider className="text-white" />
      </div>

      <div className="sm:flex sm:items-center sm:justify-between">
        <ul className="block items-center mx-2">
          <img src={logo} className="h-[80px]" alt="corporate Logo" />
          <li className="text-white mx-2 mb-2">
            <Zoom>Available on web and also on</Zoom>
          </li>
          <div className="flex flex-items">
            <Bounce left>
              <li>
                <button
                  type="submit"
                  className="group flex flex-items items-center mx-1 relative justify-center rounded-md bg-bggray py-2 px-2 text-sm font-semibold text-white hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                >
                  <img className="h-7" src={android} alt="Android Link" />
                  Android
                </button>
              </li>
            </Bounce>
            <Bounce right>
              <li>
                <button
                  type="submit"
                  className="group flex flex-items items-center mx-1 relative justify-center rounded-md bg-bggray py-2 px-3 text-sm font-semibold text-white hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                >
                  <img className="h-7" src={ios} alt="IOS Link to download" />
                  iOS
                </button>
              </li>
            </Bounce>
          </div>
        </ul>
        <ul className=" items-center  px-3  text-sm text-white sm:mb-0 dark:text-gray-400">
          <li className="mr-4 hover:bggreen md:mr-6 text-lg mb-3">
            Get Started with Us
          </li>
          <li className="mb-3">
            <NewLetterInput
              name="email"
              value={email}
              onChange={(res) => emailSet(res.target.value)}
              placeholder="Your Email"
            />
          </li>
          <li className="mb-3">
            <button
              type="submit"
              className="group relative py-3 flex w-60 justify-center rounded-md bg-bggreen  px-3 text-sm font-semibold text-white hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
            >
              Sign Up
            </button>
          </li>
        </ul>
      </div>

      <div className="px-3 py-6 bg-black dark:bg-gray-700 md:flex md:items-center md:justify-between">
        <span className="text-sm text-gray-500 dark:text-gray-300 sm:text-center">
          © 2023{" "}
          <Link to="#" className="text-white" href="https://flowbite.com/">
            Corporate Set-up
          </Link>
          . All Rights Reserved.
        </span>
        <div className="flex mt-4 space-x-6 sm:justify-center md:mt-0">
          <Link
            href="#"
            className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
          >
            {/* <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" /></svg> */}
            <img src={foot1} alt="Our Facebook account" />
            <span className="sr-only">Facebook page</span>
          </Link>
          <Link
            href="#"
            className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
          >
            <img src={foot2} alt="Our Instagram account" />
            <span className="sr-only">Instagram page</span>
          </Link>
          <Link
            href="#"
            className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
          >
            <img src={foot3} alt="Our Twitter account" />
            <span className="sr-only">Twitter page</span>
          </Link>
          <Link
            href="#"
            className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
          >
            <img src={foot4} alt="Our Github Account" />
            <span className="sr-only">GitHub account</span>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
