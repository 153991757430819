import React from "react";
import terms from "../../../constants/terms_and_condition";

const TermsAndCondition = () => {
  return (
    <div className="px-14">
      <h1 className="font-bold text-3xl pb-10">Terms and Conditions</h1>
      <p className="text-[14px] md:text-[15px] my-2">
        THE WEBSITE CORPORATE-SETUP.COM (THE “SITE”) AND RELATED PLATFORMS,
        PRODUCTS, AND SERVICES AVAILABLE ON OR FROM THE SITE (THE “SERVICES”)
        ARE OWNED AND OPERATED BY CORPORATE-SETUP LTD (TOGETHER WITH ITS
        AFFILIATES, SUCCESSORS, AND ASSIGNS HEREINAFTER REFERRED TO AS “OUR”,
        “WE,” OR “US”). BY ACCESSING AND USING THE SITE AND THE SERVICES, YOU
        ACKNOWLEDGE AND AGREE TO BE BOUND BY THESE GENERAL TERMS AND CONDITIONS,
        “TERMS AND CONDITIONS”, WHICH SET FORTH THE ENTIRE AGREEMENT PERTAINING
        TO YOUR USE OF THE SITE AND SERVICES, AND SUPERSEDE ALL PRIOR VERSIONS
        OF THE TERMS AND CONDITIONS. THESE TERMS AND CONDITIONS ARE LEGALLY
        BINDING AGREEMENTS. IF YOU USE THE SITE AND THE SERVICES THEREIN OR
        REPRESENTATIVE THAT HAVE THE LEGAL AUTHORITY TO BIND SUCH INDIVIDUAL OR
        ENTITY AND YOU GUARANTEE COMPLIANCE BY SUCH INDIVIDUAL OR ENTITY WITH
        THESE TERMS AND CONDITIONS. WE RESERVE THE SOLE RIGHT AT ANY TIME TO
        MODIFY, DISCONTINUE, OR TERMINATE ANY SERVICE OR THE SITE, OR CHANGE,
        ADD, OR DELETE PORTIONS OF THESE TERMS AND CONDITIONS WITHOUT NOTICE. WE
        WILL POST CHANGES TO THESE TERMS AND CONDITIONS, IT IS YOUR
        RESPONSIBILITY TO CHECK THE SITE PERIODICALLY FOR CHANGES. YOUR
        CONTINUED USE OF THE SITE AND SERVICES AFTER ANY CHANGES CONSTITUTES
        YOUR ACCEPTANCE OF THE REVISED TERMS AND CONDITIONS.
      </p>
      <p className="text-[14px] md:text-[15px] my-2">
        IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, DO NOT USE THE SITE
        OR SERVICES.
      </p>
      {terms.terms.map((item) => (
        <div key={item.key} className="my-4">
          <p className="font-bold my-3">
            {item.key}. {item.title}
          </p>
          <ul className="my-2">
            {item.children?.map((child) => (
              <li className="my-2" key={child.key}>
                {child.description}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default TermsAndCondition;
